import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

export const auth = {
    login: (token) => {
        const decodedToken = jwtDecode(token);
        let expires = new Date(decodedToken.exp * 1000);
        Cookies.set('access_token', token, { expires, path: '/' });
    },
    check: () => {
        const token = auth.getToken();

        if (token) {
            const decodedToken = jwtDecode(token);
            const now = new Date().getTime() / 1000;
            if (decodedToken.exp > now) {
                return true;
            } else {
                alert('Your session has expired. Please log in again.');
                auth.logout();
                return false;
            }
        }
        return false;
    },
    logout: () => {
        Cookies.remove('access_token');
    },
    getToken: () => {
        return Cookies.get('access_token');
    },
};
