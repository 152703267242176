import React from 'react';
import { Link } from 'react-router-dom';
import './home.css';
import { Col, Row, Card, Image } from 'antd';
import { useRecoilState } from 'recoil';
import { sideNavCollapsedState } from '../../Recoil/recoilState';

import globalSvg from '../../Assets/Icons/HomeMasterCardIcons/Global.svg';
import KMperMonthSvg from '../../Assets/Icons/HomeMasterCardIcons/KmMonth.svg';
import modelSvg from '../../Assets/Icons/HomeMasterCardIcons/model2.svg';
import labourSvg from '../../Assets/Icons/HomeMasterCardIcons/Wallet.svg';
import itemSvg from '../../Assets/Icons/HomeMasterCardIcons/Items.svg';
import sundrySvg from '../../Assets/Icons/HomeMasterCardIcons/Sundry.svg';
import modelServiceSvg from '../../Assets/Icons/HomeMasterCardIcons/Model Service Interval.svg';
import gspConfigurationSvg from '../../Assets/Icons/HomeMasterCardIcons/GSP Configuration ic.svg';
import gspOverviewSvg from '../../Assets/Icons/HomeMasterCardIcons/GSP Overview.svg';
import notificationBellSvg from '../../Assets/Icons/HomeMasterCardIcons/Notification Preference Bell.svg';
import auditReporting from '../../Assets/Icons/HomeMasterCardIcons/Audit Reporting.svg';
import categorySvg from '../../Assets/Icons/HomeMasterCardIcons/category.svg';

export const homeMasterData = [
    {
        id: 1,
        masterDateName: 'Model',
        route: '/models',
        icon: modelSvg,
    },
    {
        id: 2,
        masterDateName: 'KM/Month',
        route: '/km-month',
        icon: KMperMonthSvg,
    },
    {
        id: 3,
        masterDateName: 'Labour Rates',
        route: '/labour-rate',
        icon: labourSvg,
    },

    {
        id: 4,
        masterDateName: 'Parts',
        route: '/parts',
        icon: itemSvg,
    },

    {
        id: 5,
        masterDateName: 'Sundry',
        route: '/sundry',
        icon: sundrySvg,
    },
    {
        id: 6,
        masterDateName: 'System',
        route: '/system',
        icon: globalSvg,
    },
    {
        id: 7,
        masterDateName: 'Category Master',
        route: '/category',
        icon: categorySvg,
    },
];

export const mainCardlabels = [
    {
        id: 1,
        description: 'Configure the applicable service intervals for a vehicle model',
        label: 'Model Service Interval',
        icon: modelServiceSvg,
        route: '/model-service-interval',
    },
    {
        id: 2,
        description:
            'Configure the technical information (parts, oils & labour instructions) for a vehicle Model Service Interval',
        label: 'Technical Configuration',
        icon: gspConfigurationSvg,
        route: '/technical-configuration',
    },
    {
        id: 3,
        description: 'Configure the GSP for a vehicle model for each applicable service interval',
        label: 'GSP Configuration',
        icon: gspConfigurationSvg,
        route: '/gsp-configuration',
    },
    {
        id: 4,
        description: 'Provide an overview of the GSP configured for each vehicle model',
        label: 'GSP Overview',
        icon: gspOverviewSvg,
        route: '/gsp-overview',
    },
    {
        id: 5,
        description: 'Configure notification recipients to ensure configured users stay informed about updates.',
        label: 'Notification Preferences',
        icon: notificationBellSvg,
        route: '/notification-preferences',
    },
    {
        id: 6,
        description: 'Track and analyze all system changes for enhanced accountability and actionable insights.',
        label: 'Audit & Reporting',
        icon: auditReporting,
        route: '/audit-reporting',
    },
];

const Home = (props) => {
    const [collapsed, setCollapsed] = useRecoilState(sideNavCollapsedState);
    const toggleSideNav = () => {
        setCollapsed(true);
    };
    return (
        <div style={{ margin: '12px' }}>
            <div className="home-bg-container">
                <h2 className="main-title">Master Data Configuration</h2>
                <Row gutter={[14, 14]} justify="start">
                    {homeMasterData.map((eachObject) => (
                        <Col xs={12} sm={12} md={collapsed ? 6 : 8} lg={6} xl={6} key={eachObject.id}>
                            <Link to={eachObject.route}>
                                <Card className="master-card" bordered={true} onClick={toggleSideNav}>
                                    <Image className="style-icons" alt="icon" src={eachObject.icon} preview={false} />
                                    <h2 className="cards-label">{eachObject.masterDateName}</h2>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
            <Row gutter={[12, 12]} justify="start">
                {mainCardlabels.map((eachObject) => (
                    <Col xs={24} sm={12} md={collapsed ? 12 : 12} lg={8} xl={6} xxl={6} key={eachObject.id}>
                        <Link to={eachObject.route} onClick={toggleSideNav}>
                            <Card className="config-container" style={{ height: collapsed ? '185px' : '220px' }}>
                                <div>
                                    <h2 className="cards-label">{eachObject.label}</h2>
                                </div>
                                <div className="config-card-para-cont">
                                    <img src={eachObject.icon} alt="icon" className="img-card" />
                                    <p className="config-cards-description">{eachObject.description}</p>
                                </div>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default Home;
