import React, { useEffect, useRef, useState } from 'react';
import './system.css';
import { Input, Form, message, Col, Tooltip, Space, Row } from 'antd';
import { commonSorter } from '../../../CommonComponents/SortingLogic';
import EmptyMasterData from '../EmptyMasterData/EmptyMasterData';
import CommonTable from '../../../CommonComponents/Table/CommonTable';
import { listSystemItems } from '../../../Services/Endpoints';
import apiService from '../../../Services/commonServices';
import ModalMaster from '../../../CommonComponents/modals/Modal-Master/ModalMaster';
import { handleDescriptionInputChange, onHandleValueInputChange } from '../commonInputFunctions';

const System = () => {
    const hiddenSubmitButton = useRef(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [allSystemItems, setAllSystemItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editSystemItem, setEditSystemItem] = useState({});
    const [ismodalAddState, setIsModalAddState] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [orginalSystemItems, setOriginalSystemItems] = useState([]);
    const [disableSave, setDisableSave] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
        value: '',
        description: '',
    });

    useEffect(() => {
        setIsLoading(true);
        getAllSystemItems();
    }, []);

    const makeSystemItemEmpty = () => {
        setFormData({
            name: '',
            value: '',
            description: '',
        });
    };

    const fetchData = async () => {
        try {
            const responseData = await apiService.get(listSystemItems);
            setAllSystemItems(responseData.data);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleCancel = () => {
        setFormErrors(false);
        setIsModalOpen(false);
        setIsModalAddState(true);
        makeSystemItemEmpty();
        setDisableSave(true);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleFinish();
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!formData?.name) {
            errors.name = 'This field is required.';
        }

        if (!formData?.value) {
            errors.value = 'This field is required.';
        } else if (Number(formData?.value) <= 0) {
            errors.value = 'Value must be greater than zero.';
        }

        const nameExists = allSystemItems?.some(
            (item) =>
                item?.name.trim()?.toLowerCase() === formData.name.trim()?.toLowerCase() &&
                item.id !== editSystemItem.id
        );

        if (nameExists) {
            errors.name = 'Duplicate name. Please enter a unique name.';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onHandleNameInputChange = (e) => {
        setDisableSave(false);
        const { value } = e.target;

        if (value.trim().length === 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                name: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'This field is required.',
            }));
        } else if (value.length > 50) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Max 50 characters.',
            }));
        } else {
            const nameExists = allSystemItems?.some(
                (item) =>
                    item?.name.trim()?.toLowerCase() === value.trim()?.toLowerCase() &&
                    (!editSystemItem || item.id !== editSystemItem.id)
            );

            setFormData((prevFormData) => ({
                ...prevFormData,
                name: value,
            }));

            if (nameExists) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    name: 'Duplicate name. Please enter a unique name.',
                }));
            } else {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    name: '',
                }));
            }
        }
    };

    const onHandleValueChange = (e) => {
        setDisableSave(false);
        onHandleValueInputChange(e, formData, setFormData, formErrors, setFormErrors);
    };

    const onHandleDescriptionChange = (e) => {
        setDisableSave(false);
        handleDescriptionInputChange(e, setFormData, setFormErrors, setDisableSave, formErrors);
    };

    const onHandleSearch = (e) => {
        const { value } = e.target;
        const filteredItems = orginalSystemItems.filter((item) => {
            return (
                (item?.name && item?.name?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.value && item?.value?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.description && item.description?.toLowerCase().includes(value?.toLowerCase()))
            );
        });
        setAllSystemItems(filteredItems);
    };

    const getAllSystemItems = async () => {
        try {
            const responseData = await apiService.get(listSystemItems);
            setAllSystemItems(responseData.data);
            setOriginalSystemItems(responseData.data);
            setIsEmpty(responseData.data.length === 0);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const onHandleAddSystemItem = async (postNewSystemItem) => {
        try {
            const response = await apiService.post(listSystemItems, postNewSystemItem);
            message.success(response.message);
            setFormErrors({});
            getAllSystemItems();
        } catch (error) {
            message.error(error.message);
            getAllSystemItems();
        }
    };

    const onHandleBlur = () => {
        const trimmedValue = formData?.value?.trim();

        if (trimmedValue === '') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                value: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                value: 'This field is required.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                value: '',
            }));
        }
    };

    const onHandleEditSystemItem = (record) => {
        setIsModalOpen(true);
        setIsModalAddState(false);
        setDisableSave(true);
        const editItem = allSystemItems.find((eachObject) => eachObject.id === record?.id);
        setEditSystemItem(editItem);
        setFormData({
            name: editItem.name,
            value: editItem.value,
            description: editItem.description,
        });
    };

    const onHandleUpdateSystemItem = async (updatedSystemItem) => {
        try {
            const response = await apiService.put(`${listSystemItems}${editSystemItem.id}`, updatedSystemItem);
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        }
        getAllSystemItems();
    };

    const handleFinish = () => {
        setIsModalOpen(false);
        setIsLoading(true);

        let updatedValue = parseFloat(formData.value);
        if (Number.isInteger(updatedValue)) {
            updatedValue = updatedValue.toFixed(2);
        }
        if (!Number.isInteger(updatedValue) && updatedValue.toString().split('.')[1]?.length === 1) {
            updatedValue = updatedValue.toFixed(2);
        }

        if (ismodalAddState) {
            const postNewSundryItem = {
                ...formData,
                name: formData.name.trim(),
                description: formData.description.trim(),
                value: updatedValue,
            };
            onHandleAddSystemItem(postNewSundryItem);
        } else {
            const updatedItem = {
                ...editSystemItem,
                name: formData.name.trim(),
                description: formData.description.trim(),
                value: updatedValue,
            };
            onHandleUpdateSystemItem(updatedItem);
        }
        makeSystemItemEmpty();
    };

    const onHandleDeleteItem = async (record) => {
        try {
            const response = await apiService.delete(`${listSystemItems}${record.id}`);
            message.success(response.message);
            getAllSystemItems();
        } catch (error) {
            message.warning(error.message);
        }
    };

    const handleExcelData = (json) => {};

    const columns = [
        {
            title: 'Code',
            dataIndex: 'name',
            render: (_, record) => <span className="record-text">{record?.name}</span>,
            sorter: commonSorter('name'),
            width: 300,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            render: (_, record) => <span className="record-text">{record?.value}</span>,
            sorter: commonSorter('value'),
            width: 250,
        },
        {
            title: 'Comments',
            className: 'description-cell-style',
            dataIndex: 'description',
            render: (_, record) => (
                <Tooltip
                    height={500}
                    className="table-cell-tooltip-style"
                    placement="topLeft"
                    title={record?.description?.length > 70 ? record?.description : ''}
                >
                    <span className="record-text">{record?.description}</span>
                </Tooltip>
            ),
            width: 656,
        },
    ];

    return (
        <>
            <div>
                {isEmpty ? (
                    <EmptyMasterData
                        onClickAdd={() => {
                            setIsModalAddState(true);
                            setIsModalOpen(true);
                        }}
                    />
                ) : (
                    <div>
                        <CommonTable
                            columns={columns}
                            dataSource={allSystemItems}
                            rowKey="id"
                            isLoading={isLoading}
                            onClickAdd={() => {
                                setIsModalAddState(true);
                                setIsModalOpen(true);
                            }}
                            onChange={onHandleSearch}
                            handleExcelData={handleExcelData}
                            onEdit={onHandleEditSystemItem}
                            onDelete={onHandleDeleteItem}
                            resourceName={'System'}
                        />
                    </div>
                )}

                <div className="system-modal-parent">
                    <ModalMaster
                        openModal={isModalOpen}
                        closeModal={handleCancel}
                        label={ismodalAddState ? 'Add System Item' : 'Edit System Item'}
                        onClickSubmit={handleSubmit}
                        isDisableOkButton={!ismodalAddState && disableSave}
                        buttonText={ismodalAddState ? 'Add' : 'Save'}
                        width={566}
                        content={
                            <Form
                                name="wrap"
                                labelCol={{}}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                style={{ maxWidth: 600 }}
                                onFinish={handleSubmit}
                            >
                                <div className="form-inputs-parent">
                                    <Row gutter={16}>
                                        <Col>
                                            <Form.Item
                                                label="Code"
                                                required
                                                validateStatus={formErrors?.name ? 'error' : ''}
                                                help={formErrors.name}
                                                style={{ width: '322px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    placeholder="Enter Name"
                                                    value={formData?.name}
                                                    onChange={onHandleNameInputChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                label="Value"
                                                required
                                                validateStatus={formErrors.value ? 'error' : ''}
                                                help={formErrors.value}
                                                style={{ width: '170px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    placeholder="Enter Value"
                                                    value={formData?.value}
                                                    onBlur={onHandleBlur}
                                                    onChange={onHandleValueChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        label="Comments"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        validateStatus={formErrors.description ? 'error' : ''}
                                        help={formErrors?.description}
                                    >
                                        <Input.TextArea
                                            placeholder="Enter Comments"
                                            value={formData?.description}
                                            onChange={onHandleDescriptionChange}
                                        />
                                        {formErrors?.showRemainingChar && (
                                            <span className="remaining-characters-style">{`Remaining ${formData?.remainingCharlength} characters`}</span>
                                        )}
                                    </Form.Item>
                                </div>
                                <button ref={hiddenSubmitButton} type="submit" style={{ display: 'none' }} />
                            </Form>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default System;
