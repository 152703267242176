export const itemType = [
    {
        id: 1,
        name: 'Part',
    },
    {
        id: 2,
        name: 'Labour Instruction',
    },
];

export const actionType = [
    { id: 0, name: 'Inspect' },
    { id: 1, name: 'Replace' },
    { id: 2, name: 'Perform' },
    { id: 3, name: 'N/A' },
];
