import { FilterOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import './filter.css';
import { Drawer } from 'antd';
import { DatePicker, Select, Button, Tooltip } from 'antd';

export const CustomFilter = ({ filterData, uniqueSapCodes, allEngineType, resourceName, modelDetail }) => {
    const [open, setOpen] = useState(false);
    const { Option } = Select;
    const { RangePicker } = DatePicker;
    const [sapCode, setSapCode] = useState('All');
    const [fuelType, setFuelType] = useState('All');
    const [modelName, setModelName] = useState('All');
    const [engineType, setEngineType] = useState('All');
    const [code, setCode] = useState('All');
    const [uniqueCode, setUniqueCode] = useState([]);
    const [uniqueEngineType, setUniqueEngineType] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState('All');
    const [selectedFeature, setSelectedFeature] = useState('All');
    const events = ['All', 'CREATE', 'UPDATE', 'DELETE'];
    const features = [
        'All',
        'KM/Month',
        'Models',
        'Technical Configuration',
        'Parts',
        'Labour Rate',
        'Sundries',
        'System',
        'Model Service Interval',
    ];
    const [datePopUp, setDatePopUp] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [confirmedDates, setConfirmedDates] = useState([]);

    const uniqueModelNames = [...new Set(modelDetail?.map((model) => model?.modelDetails?.modelName))];

    useEffect(() => {
        const uniqueFactoryCodes = [...new Set(modelDetail?.map((item) => item.modelDetails.factoryCode))];
        const uniqueEngineTypes = [...new Set(modelDetail?.map((item) => item.modelDetails.engineNumber))];

        setUniqueCode(uniqueFactoryCodes);
        setUniqueEngineType(uniqueEngineTypes);
    }, [modelDetail]);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setDatePopUp(false);
    };

    const onchangeHandleSapCode = (e) => {
        setSapCode(e);
    };

    const onchangeHandleModelName = (e) => {
        setModelName(e);
    };

    const onchangeHandleFuelType = (e) => {
        setFuelType(e);
    };

    const onchangeHandleEngineType = (e) => {
        setEngineType(e);
    };

    const onchangeHandleCode = (e) => {
        setCode(e);
    };

    const onchangeHandleEvent = (e) => {
        setSelectedEvent(e);
    };

    const onchangeHandleFeature = (e) => {
        setSelectedFeature(e);
    };
    const handleClearAll = () => {
        setDatePopUp(false);
        setSelectedDates([]);
        setConfirmedDates([]);
    };

    const handleSelectRange = () => {
        setConfirmedDates(selectedDates);
        setDatePopUp(false);
    };

    const handleDateChange = (dates) => {
        setSelectedDates(dates);
    };

    const handleRangeChange = (dates) => {
        setSelectedDates(dates);
        setDatePopUp(true);
    };

    const handleFilter = () => {
        resourceName == 'gsp-overview'
            ? filterData({
                  code: code,
                  engine_type: engineType,
                  model_Name: modelName,
              })
            : resourceName == 'Model'
              ? filterData({
                    sap_code: sapCode,
                    fuel_type: fuelType,
                })
              : filterData({
                    date_range: confirmedDates,
                    event: selectedEvent,
                    feature: selectedFeature,
                });
        setOpen(false);
    };

    const handleClearFilter = () => {
        setFuelType('All');
        setSapCode('All');
        setModelName('All');
        setEngineType('All');
        setCode('All');
        setSelectedDates([]);
        setConfirmedDates([]);
        setSelectedEvent('All');
        setSelectedFeature('All');
        resourceName == 'gsp-overview'
            ? filterData({ code: 'All', engine_type: 'All', model_Name: 'All' })
            : resourceName == 'Model'
              ? filterData({ sap_code: 'All', fuel_type: 'All' })
              : filterData({ date_range: [], event: 'All', feature: 'All' });
    };

    const getTooltipContent = () => {
        if (!selectedDates || selectedDates.length !== 2) return 'Select a date range';
        return `From: ${selectedDates[0]?.format('YYYY-MM-DD')} To: ${selectedDates[1]?.format('YYYY-MM-DD')}`;
    };

    return (
        <div className="custom-search-parent-container">
            <Button
                onClick={() => showDrawer()}
                shape="square"
                type="primary"
                className="custom-filter"
                icon={<FilterOutlined />}
            />
            {/* <FilterOutlined onClick={showDrawer} className="custom-filter" /> */}
            <Drawer
                title="Filter"
                className="custom-drawer"
                onClose={onClose}
                open={open}
                footer={
                    <div style={{ textAlign: 'center' }}>
                        <Button style={{ marginRight: 8, width: 101.5 }} onClick={handleClearFilter}>
                            Clear All
                        </Button>
                        <Button type="primary" style={{ width: 101.5 }} onClick={handleFilter}>
                            Apply
                        </Button>
                    </div>
                }
            >
                {resourceName == 'gsp-overview' ? (
                    <>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Code</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="All"
                                    className="dv-select"
                                    value={code}
                                    onChange={(e) => {
                                        onchangeHandleCode(e);
                                    }}
                                    showSearch
                                >
                                    {uniqueCode.map((code, index) => (
                                        <option key={index} value={code}>
                                            {code}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Model Name</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="All"
                                    className="dv-select"
                                    value={modelName}
                                    onChange={(e) => {
                                        onchangeHandleModelName(e);
                                    }}
                                    showSearch
                                >
                                    {uniqueModelNames.map((modelName, index) => (
                                        <option key={index} value={modelName}>
                                            {modelName}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Engine Type</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="All"
                                    className="dv-select"
                                    value={engineType}
                                    onChange={(e) => {
                                        onchangeHandleEngineType(e);
                                    }}
                                    showSearch
                                >
                                    {uniqueEngineType.map((engineType, index) => (
                                        <option key={index} value={engineType}>
                                            {engineType}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </>
                ) : resourceName == 'Model' ? (
                    <>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">SAP Code</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="All"
                                    className="dv-select"
                                    value={sapCode}
                                    onChange={(e) => {
                                        onchangeHandleSapCode(e);
                                    }}
                                    showSearch
                                >
                                    {uniqueSapCodes?.map((sapCode, index) => (
                                        <Option value={sapCode.sapCode} key={sapCode.sapCode}>
                                            {sapCode.sapCode}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Fuel Type</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="all"
                                    className="dv-select"
                                    value={fuelType}
                                    onChange={(e) => {
                                        onchangeHandleFuelType(e);
                                    }}
                                >
                                    {allEngineType?.map((option) => (
                                        <Option key={option.name} value={option.name}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Date Range</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Tooltip title={getTooltipContent()} placement="top">
                                    <RangePicker
                                        open={datePopUp}
                                        value={selectedDates}
                                        onChange={handleRangeChange}
                                        onCalendarChange={handleDateChange}
                                        onClick={() => setDatePopUp(true)}
                                        renderExtraFooter={() => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    margin: '10px',
                                                }}
                                            >
                                                <Button
                                                    onClick={handleClearAll}
                                                    type="default"
                                                    size="small"
                                                    className="rangepicker-btn"
                                                >
                                                    Clear and Dismiss
                                                </Button>
                                                <Button
                                                    onClick={handleSelectRange}
                                                    type="primary"
                                                    size="small"
                                                    className="rangepicker-btn"
                                                >
                                                    Select Range
                                                </Button>
                                            </div>
                                        )}
                                        placeholder={['From', 'To']}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Event</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="All"
                                    className="dv-select"
                                    value={selectedEvent}
                                    onChange={(e) => {
                                        onchangeHandleEvent(e);
                                    }}
                                    showSearch
                                >
                                    {events.map((event, index) => (
                                        <option key={index} value={event}>
                                            {event}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div
                            style={{
                                padding: 24,
                                borderBottom: 'rgba(5, 5, 5, 0.06)1px solid',
                            }}
                        >
                            <div className="div-label">Feature</div>
                            <div style={{ padding: '5px 0px' }}>
                                <Select
                                    defaultValue="All"
                                    className="dv-select"
                                    value={selectedFeature}
                                    onChange={(e) => {
                                        onchangeHandleFeature(e);
                                    }}
                                    showSearch
                                >
                                    {features.map((feature, index) => (
                                        <option key={index} value={feature}>
                                            {feature}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </>
                )}
            </Drawer>
        </div>
    );
};
