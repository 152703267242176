import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../Services/authService';

const Main = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        debugger;
        let queryParams = new URLSearchParams(location.search);
        let token = queryParams.get('token');
        if (token) {
            auth.login(token);
            navigate('/');
        } else {
            if (auth.check()) {
                navigate('/');
            } else {
                navigate('/login');
            }
        }
    }, []);

    return <></>;
};

export default Main;
