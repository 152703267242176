import React, { useEffect, useRef, useState } from 'react';
import { Input, Form, message, Row, Col, Tooltip, Modal } from 'antd';
import { commonSorter } from '../../CommonComponents/SortingLogic';
import EmptyMasterData from '../MasterData/EmptyMasterData/EmptyMasterData';
import CommonTable from '../../CommonComponents/Table/CommonTable';
import { changeRecipientStatus, listRecipients } from '../../Services/Endpoints';
import apiService from '../../Services/commonServices';
import ModalMaster from '../../CommonComponents/modals/Modal-Master/ModalMaster';
import { handleDescriptionInputChange } from '../MasterData/commonInputFunctions';
import { WarningOutlined } from '@ant-design/icons';

const Notification = () => {
    const [form] = Form.useForm();
    const hiddenSubmitButton = useRef(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [allRecipients, setAllRecipients] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editRecipient, setEditRecipient] = useState({});
    const [ismodalAddState, setIsModalAddState] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [orginalRecipients, setOriginalRecipients] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const [toggleStates, setToggleStates] = useState({});

    const [formData, setFormData] = useState({
        code: '',
        value: '',
        description: '',
    });

    useEffect(() => {
        setIsLoading(true);
        getAllRecipients();
    }, []);

    useEffect(() => {
        const initialToggleStates = allRecipients.reduce((acc, record) => {
            acc[record.id] = record.isActive;
            return acc;
        }, {});

        setToggleStates(initialToggleStates);
    }, [allRecipients]);

    const makeRecipientsEmpty = () => {
        setFormData({
            code: '',
            value: '',
            description: '',
        });
    };

    const handleCancel = () => {
        setFormErrors(false);
        setIsModalOpen(false);
        setIsModalAddState(true);
        makeRecipientsEmpty();
        setDisableSave(true);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleFinish();
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!formData?.name) {
            errors.name = 'This field is required.';
        }

        if (!formData?.email) {
            errors.email = 'This field is required.';
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.com\.au$/;

        if (formData?.email && !emailRegex.test(formData.email)) {
            errors.email = 'Email must be in the correct format.';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onHandleNameInputChange = (e) => {
        setDisableSave(false);
        const { value } = e.target;

        if (value.trim().length === 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                name: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'This field is required.',
            }));
            return;
        }

        if (value.length > 50) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Max 50 characters.',
            }));
            return;
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            name: value,
        }));
    };

    const onHandleEmailInputChange = (e) => {
        setDisableSave(false);
        const { value } = e.target;

        if (value.trim().length === 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                email: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: 'This field is required.',
            }));
            return;
        }

        if (value.length > 50) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Max 50 characters.',
            }));
            return;
        }

        const isDuplicateEmail = allRecipients.some((recipient) => recipient.email === value);

        setFormData((prevFormData) => ({
            ...prevFormData,
            email: value,
        }));

        if (isDuplicateEmail) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email already exists. Enter new email.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: '',
            }));
        }
    };

    const onHandleDescriptionChange = (e) => {
        setDisableSave(false);
        handleDescriptionInputChange(e, setFormData, setFormErrors, setDisableSave, formErrors);
    };

    const onHandleSearch = (e) => {
        const { value } = e.target || '';
        const filteredItems = orginalRecipients?.filter((item) => {
            return (
                !value ||
                (item?.name && item.name?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.email && item.email?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.description && item.description?.toLowerCase().includes(value?.toLowerCase()))
            );
        });
        setAllRecipients(filteredItems);
    };

    const getAllRecipients = async () => {
        try {
            const responseData = await apiService.get(listRecipients);
            setAllRecipients(responseData.data);
            setOriginalRecipients(responseData.data);
            setIsEmpty(responseData.data.length === 0);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const onHandleAddRecipient = async (postNewRecipient) => {
        try {
            const response = await apiService.post(listRecipients, postNewRecipient);
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        }
        getAllRecipients();
    };

    const onHandleEditSundryItem = (record) => {
        setIsModalOpen(true);
        setIsModalAddState(false);
        setDisableSave(true);
        const editItem = allRecipients.find((eachObject) => eachObject.id === record?.id);
        setEditRecipient(editItem);
        setFormData({
            name: editItem.name,
            email: editItem.email,
            description: editItem.description,
        });
    };

    const onHandlevalueBlur = () => {
        const trimmedValue = formData?.value?.trim();

        if (trimmedValue === '') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                value: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                value: 'This field is required.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                value: '',
            }));
        }
    };

    const onHandleDescriptionBlur = () => {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            showRemainingChar: false,
        }));
    };

    const onHandleUpdateRecipient = async (updatedRecipient) => {
        try {
            const response = await apiService.put(`${listRecipients}${editRecipient.id}`, updatedRecipient);
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        }
        getAllRecipients();
    };

    const handleFinish = () => {
        setIsModalOpen(false);
        setIsLoading(true);
        setFormErrors({});

        if (ismodalAddState) {
            const postNewRecipient = {
                ...formData,
                email: formData.email,
                name: formData.name.trim(),
                description: formData.description.trim(),
            };

            onHandleAddRecipient(postNewRecipient);
        } else {
            const updatedItem = {
                ...editRecipient,
                name: formData.name.trim(),
                description: formData.description.trim(),
                email: formData.email,
            };
            onHandleUpdateRecipient(updatedItem);
        }
        makeRecipientsEmpty();
    };

    const onHandleDeleteItem = async (record) => {
        try {
            const response = await apiService.delete(`${listRecipients}${record.id}`);
            message.success(response.message);
        } catch (error) {
            message.warning(error.message);
            console.error(error.message);
        }
        getAllRecipients();
    };

    const handleToggle = (record, checked) => {
        setToggleStates((prevState) => ({
            ...prevState,
            [record.id]: checked,
        }));

        Modal.confirm({
            title: checked
                ? 'Confirm addition of recipient to Notification receivers list?'
                : 'Confirm removal of recipient from Notification receivers list?',
            className: 'delete-popup',
            icon: <WarningOutlined />,
            style: { top: 0 },
            content: (
                <>
                    Are you sure you want to {checked ? 'add' : 'remove'} this selected recipient ‘{record.name}’{' '}
                    {checked ? 'to' : 'from'} Notification receivers list?
                    <br />
                    <br />
                    {checked
                        ? 'This recipient will receive notifications of Technical Configuration updates.'
                        : 'This recipient will no longer receive notifications of Technical Configuration updates.'}
                </>
            ),
            okText: checked ? 'Add' : 'Remove',
            cancelText: 'Cancel',
            onOk() {
                setToggleStates((prevState) => ({
                    ...prevState,
                    [record.id]: checked,
                }));
                onhandleStatusChange(record.id, checked);
            },
            onCancel() {
                setToggleStates((prevState) => ({
                    ...prevState,
                    [record.id]: !checked,
                }));
            },
        });
    };

    const onhandleStatusChange = async (id, checked) => {
        try {
            const response = await apiService.put(
                `${listRecipients}${changeRecipientStatus}?notificationPreferenceId=${id}&status=${checked}`
            );
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        }
        getAllRecipients();
    };

    const handleExcelData = (json) => {};

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, record) => <span className="record-text">{record?.name}</span>,
            sorter: commonSorter('name'),
            width: 300,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (_, record) => <span className="record-text">{record?.email}</span>,
            sorter: commonSorter('email'),
            width: 250,
        },
        {
            title: 'Description',
            className: 'description-cell-style',
            dataIndex: 'description',
            render: (_, record) => (
                <Tooltip
                    height={500}
                    className="table-cell-tooltip-style"
                    placement="topLeft"
                    title={record?.description?.length > 70 ? record?.description : ''}
                >
                    <span className="record-text">{record?.description}</span>
                </Tooltip>
            ),
            width: 656,
        },
    ];

    return (
        <>
            <div>
                {isEmpty ? (
                    <EmptyMasterData
                        onClickAdd={() => {
                            setIsModalAddState(true);
                            setIsModalOpen(true);
                        }}
                    />
                ) : (
                    <div>
                        <CommonTable
                            columns={columns}
                            dataSource={allRecipients}
                            rowKey="id"
                            isLoading={isLoading}
                            onClickAdd={() => {
                                setIsModalAddState(true);
                                setIsModalOpen(true);
                            }}
                            recordsState={toggleStates}
                            onChange={onHandleSearch}
                            handleExcelData={handleExcelData}
                            onEdit={onHandleEditSundryItem}
                            onDelete={onHandleDeleteItem}
                            onToggleNotification={handleToggle}
                            resourceName={'Recipient'}
                        />
                    </div>
                )}
                <div className="system-modal-parent">
                    <ModalMaster
                        openModal={isModalOpen}
                        closeModal={handleCancel}
                        label={ismodalAddState ? 'Add Recipient' : 'Edit Recipient'}
                        onClickSubmit={handleSubmit}
                        width={650}
                        isDisableOkButton={!ismodalAddState && disableSave}
                        buttonText={ismodalAddState ? 'Add' : 'Save'}
                        content={
                            <Form
                                name="wrap"
                                labelCol={{}}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                style={{ maxWidth: 650 }}
                                onFinish={handleSubmit}
                            >
                                <div className="form-inputs-parent">
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Name"
                                                required
                                                validateStatus={formErrors.name ? 'error' : ''}
                                                help={formErrors.name}
                                                style={{ width: '293px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    placeholder="Enter Recipient Name"
                                                    value={formData.name}
                                                    onChange={onHandleNameInputChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Email"
                                                required
                                                validateStatus={formErrors.email ? 'error' : ''}
                                                help={formErrors.email}
                                                style={{ width: '293px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    placeholder="Enter Recipient Email"
                                                    value={formData.email}
                                                    onChange={onHandleEmailInputChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Description"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                validateStatus={formErrors.description ? 'error' : ''}
                                                help={formErrors.description}
                                            >
                                                <Input.TextArea
                                                    placeholder="Enter Description"
                                                    value={formData.description}
                                                    onChange={onHandleDescriptionChange}
                                                />
                                                {formErrors.showRemainingChar && (
                                                    <span className="remaining-characters-style">{`Remaining ${formData.remainingCharlength} characters`}</span>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <button ref={hiddenSubmitButton} type="submit" style={{ display: 'none' }} />
                            </Form>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default Notification;
