import React, { useEffect, useRef, useState } from 'react';
import './kmMonth.css';
import { Input, Form, message, Select, Tooltip, Row, Col } from 'antd';
import { commonSorter } from '../../../CommonComponents/SortingLogic';
import EmptyMasterData from '../EmptyMasterData/EmptyMasterData';
import CommonTable from '../../../CommonComponents/Table/CommonTable';
import { listIntervals, recomendedServices } from '../../../Services/Endpoints';
import apiService from '../../../Services/commonServices';
import ModalMaster from '../../../CommonComponents/modals/Modal-Master/ModalMaster';
import { handleDescriptionInputChange } from '../commonInputFunctions';

const { Option } = Select;

const KmMonth = () => {
    const hiddenSubmitButton = useRef(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [allKmMonthItems, setAllKmMonthItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editKmMonthItem, setEditKmMonthItem] = useState({});
    const [ismodalAddState, setIsModalAddState] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [orginalKmMonthItems, setOriginalKmMonthItems] = useState([]);
    const [disableSave, setDisableSave] = useState(true);
    const [recomendedServicesData, setRecomendedServicesData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(undefined);
    const [filterKms, setFilterKms] = useState([]);
    const [customSearchValue, setCustomSearchValue] = useState('');

    const [formData, setFormData] = useState({
        recommendedService: '',
        serviceIntervalCode: '',
        description: '',
        km: '',
        months: '',
    });

    useEffect(() => {
        getAllKmMonthItems();
        getRecomendedServices();
    }, []);

    const makeKmMonthItemEmpty = () => {
        setFormData({
            recommendedService: '',
            serviceIntervalCode: '',
            description: '',
            km: '',
            months: '',
        });
    };

    const handleCancel = () => {
        setFormErrors(false);
        setIsModalOpen(false);
        setIsModalAddState(true);
        makeKmMonthItemEmpty();
        setDisableSave(true);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleFinish();
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!formData?.recommendedService) {
            errors.recommendedService = 'Please select an option.';
        }
        if (!formData?.serviceIntervalCode || !formData?.serviceIntervalCode.trim()) {
            errors.serviceIntervalCode = 'This field is required.';
        } else if (formData.serviceIntervalCode.length > 50) {
            errors.serviceIntervalCode = 'Max 50 characters.';
        } else {
            const codeExists = allKmMonthItems?.some(
                (item) =>
                    item.serviceIntervalCode.trim()?.toLowerCase() ===
                        formData?.serviceIntervalCode.trim()?.toLowerCase() &&
                    formData?.recommendedService?.id === item.recommendedService?.id &&
                    (!editKmMonthItem || item.id !== editKmMonthItem.id)
            );

            if (codeExists) {
                errors.serviceIntervalCode = 'Duplicate service interval code. Please enter a unique code.';
            }
        }
        if (formData.km === null || formData.km === undefined || !String(formData?.km)?.trim()) {
            errors.km = 'This field is required.';
        } else {
            const reg = /^\d{1,20}$/;
            if (!reg.test(formData.km)) {
                errors.km = 'Please enter valid numbers only.';
            }
        }

        if (formData?.months === null || formData.months === undefined || !String(formData.months).trim()) {
            errors.months = 'This field is required.';
        } else {
            const reg = /^\d{1,10}$/;
            if (!reg.test(formData.months)) {
                errors.months = 'Please enter valid numbers only.';
            }
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onHandleRecomendedInputChange = (value) => {
        const recomendedServiceObject = recomendedServicesData.find((eachObject) => eachObject.id === value);

        const codeExists = allKmMonthItems?.some(
            (item) =>
                item.recommendedService.id === recomendedServiceObject.id &&
                item.serviceIntervalCode.trim()?.toLowerCase() === formData.serviceIntervalCode.trim()?.toLowerCase() &&
                (!editKmMonthItem || item.id !== editKmMonthItem.id)
        );

        if (codeExists) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                serviceIntervalCode: 'Duplicate service interval code. Please enter a unique code.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                serviceIntervalCode: '',
            }));
        }

        setDisableSave(false);
        setFormData((prevState) => ({
            ...prevState,
            recommendedService: recomendedServiceObject,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            recommendedService: '',
        }));
    };

    const onHandleIntervalCodeInputChange = (e) => {
        setDisableSave(false);
        const { value } = e.target;

        if (value?.trim().length === 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                serviceIntervalCode: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                serviceIntervalCode: 'This field is required.',
            }));
            return;
        }

        if (value.length > 50) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                serviceIntervalCode: 'Max 50 characters.',
            }));
            return;
        }
        const codeExists = allKmMonthItems?.some(
            (item) =>
                item.recommendedService.name === formData.recommendedService.name &&
                item.serviceIntervalCode.trim()?.toLowerCase() === value.trim()?.toLowerCase() &&
                (!editKmMonthItem || item.id !== editKmMonthItem.id)
        );
        setFormData((prevFormData) => ({
            ...prevFormData,
            serviceIntervalCode: value,
        }));

        if (codeExists) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                serviceIntervalCode: 'Duplicate service interval code. Please enter a unique code.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                serviceIntervalCode: '',
            }));
        }
    };

    const onHandleKmInputChange = (e) => {
        setDisableSave(false);
        let { value } = e.target;
        if (value.trim() === '') {
            setFormErrors({ ...formErrors, km: 'This field is required.' });
            setFormData((prevState) => ({
                ...prevState,
                km: '',
            }));
            return;
        }
        const reg = /^\d{0,20}$/;
        if (reg.test(value)) {
            setFormData((prevState) => ({
                ...prevState,
                km: value,
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                km: '',
            }));
        } else {
            if (value.length > 20) {
                setFormErrors({ ...formErrors, km: 'Max 20 characters.' });
            } else {
                setFormErrors({ ...formErrors, km: 'Numbers only.' });
            }
        }
    };

    const onHandleMonthInputChange = (e) => {
        setDisableSave(false);
        let { value } = e.target;
        if (value.trim() === '') {
            setFormErrors({ ...formErrors, months: 'This field is required.' });
            setFormData((prevState) => ({
                ...prevState,
                months: '',
            }));
            return;
        }
        const reg = /^\d{0,10}$/;

        if (reg.test(value)) {
            setFormData((prevState) => ({
                ...prevState,
                months: value,
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                months: '',
            }));
        } else {
            if (value.length > 10) {
                setFormErrors({ ...formErrors, months: 'Max 10 characters.' });
            } else {
                setFormErrors({ ...formErrors, months: 'Numbers only.' });
            }
        }
    };

    const onHandleDescriptionChange = (e) => {
        setDisableSave(false);
        handleDescriptionInputChange(e, setFormData, setFormErrors, setDisableSave, formErrors);
    };

    const onHandleSearch = (e) => {
        const { value } = e.target;
        setCustomSearchValue(value);

        const filteredItems = filterKms.filter(
            (item) =>
                (item.serviceIntervalCode && item.serviceIntervalCode?.toLowerCase().includes(value?.toLowerCase())) ||
                (item.km && item.km.toString().includes(value)) ||
                (item.months && item.months.toString().includes(value)) ||
                (item.description && item.description?.toLowerCase().includes(value?.toLowerCase())) ||
                (item.recommendedService &&
                    item.recommendedService?.name &&
                    item.recommendedService?.name?.toLowerCase().includes(value?.toLowerCase()))
        );
        setAllKmMonthItems(filteredItems);
    };

    const onHandleSelectedService = (value) => {
        setSelectedFilter(value);
        if (value !== undefined) {
            const filterData = orginalKmMonthItems.filter((eachObject) => eachObject?.recommendedService.id === value);
            setAllKmMonthItems(filterData);
            setFilterKms(filterData);
            setCustomSearchValue('');
        } else {
            setAllKmMonthItems(orginalKmMonthItems);
            setFilterKms(orginalKmMonthItems);
            setCustomSearchValue('');
        }
    };

    const getAllKmMonthItems = async () => {
        setIsLoading(true);
        try {
            const responseData = await apiService.get(listIntervals);
            if (selectedFilter === undefined) {
                const defaultData = responseData.data.filter(
                    (eachObject) =>
                        eachObject?.recommendedService.name.includes('10000') ||
                        eachObject?.recommendedService.name.includes('10,000')
                );
                setAllKmMonthItems(defaultData);
                setFilterKms(defaultData);
            } else {
                const defaultData = responseData.data.filter(
                    (eachObject) => eachObject?.recommendedService.id === selectedFilter
                );
                setAllKmMonthItems(defaultData);
                setFilterKms(defaultData);
            }

            setOriginalKmMonthItems(responseData.data);
            setIsEmpty(responseData.data.length === 0);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getRecomendedServices = async () => {
        try {
            const responseData = await apiService.get(recomendedServices);
            setRecomendedServicesData(responseData?.data);
            const defaultId = responseData?.data.find(
                (item) => item.name.includes('10,000') || item.name.includes('10000')
            );
            setSelectedFilter(defaultId.id);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const onHandleAddKmMonthItem = async (postNewKmMonthItem) => {
        try {
            const response = await apiService.post(listIntervals, postNewKmMonthItem);
            message.success(response?.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error?.message);
        }
        getAllKmMonthItems();
    };

    const onHandleEditKmMonthItem = (record) => {
        setIsModalOpen(true);
        setIsModalAddState(false);
        setDisableSave(true);
        const editItem = allKmMonthItems.find((eachObject) => eachObject?.id === record?.id);
        setEditKmMonthItem(editItem);
        setFormData({
            recommendedService: editItem.recommendedService,
            serviceIntervalCode: editItem?.serviceIntervalCode,
            km: editItem?.km,
            months: editItem?.months,
            description: editItem?.description,
        });
    };

    const onHandleUpdateKmMonthItem = async (updatedKmMonthItem) => {
        try {
            const response = await apiService.put(`${listIntervals}${updatedKmMonthItem.id}`, updatedKmMonthItem);
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);

            message.error(error.message);
        }
        getAllKmMonthItems();
    };

    const handleFinish = () => {
        setIsModalOpen(false);
        setIsLoading(true);
        let updatedKm = Number(formData.km);
        let updatedMonths = Number(formData.months);
        if (ismodalAddState) {
            const postNewKmMonthItem = {
                ...formData,
                km: updatedKm,
                months: updatedMonths,
                serviceIntervalCode: formData.serviceIntervalCode.trim(),
                description: formData.description.trim(),
                recommendedServiceId: formData.recommendedService.id,
            };
            onHandleAddKmMonthItem(postNewKmMonthItem);
        } else {
            const updatedItem = {
                ...editKmMonthItem,
                recommendedServiceId: formData.recommendedService.id,
                serviceIntervalCode: formData.serviceIntervalCode.trim(),
                description: formData.description.trim(),
                km: updatedKm,
                months: updatedMonths,
            };
            onHandleUpdateKmMonthItem(updatedItem);
        }
        makeKmMonthItemEmpty();
    };

    const onHandleDeleteItem = async (record) => {
        try {
            const response = await apiService.delete(`${listIntervals}${record.id}`);
            message.success(response.message);
        } catch (error) {
            message.warning(error.message);
            console.error(error.message);
        }
        getAllKmMonthItems();
    };

    const onHandleKmBlur = () => {
        if (!formData?.km || formData?.km === '') {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                km: 'This field is required.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                km: '',
            }));
        }
    };

    const onHandleMonthsBlur = () => {
        if (!formData?.months || formData?.months === '') {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                months: 'This field is required.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                months: '',
            }));
        }
    };

    const handleExcelData = (json) => {};
    const columns = [
        {
            title: 'Recommended Service',
            dataIndex: 'recommendedService',
            render: (_, record) => (
                <span className="record-text">
                    {record?.recommendedService ? record?.recommendedService?.name : 'N/A'}
                </span>
            ),
            sorter: (a, b) => {
                const regex = /(\d+)/;
                const extractNumber = (str) => {
                    const match = str.match(regex);
                    return match ? parseInt(match[0]) : null;
                };
                const aValue = a.recommendedService?.name ? extractNumber(a.recommendedService.name) : null;
                const bValue = b.recommendedService?.name ? extractNumber(b.recommendedService.name) : null;
                if (aValue === null || aValue === undefined) return 1;
                if (bValue === null || bValue === undefined) return -1;

                return aValue - bValue;
            },
            width: 200,
        },
        {
            title: 'Service Interval Code',
            dataIndex: 'serviceIntervalCode',
            render: (_, record) => <span className="record-text">{record?.serviceIntervalCode}</span>,
            width: 180,
        },

        {
            title: 'Comments',
            className: 'description-cell-style',
            dataIndex: 'description',
            render: (_, record) => (
                <Tooltip
                    height={500}
                    className="table-cell-tooltip-style"
                    placement="topLeft"
                    title={record?.description?.length > 15 ? record?.description : ''}
                >
                    <span className="record-text">{record?.description}</span>
                </Tooltip>
            ),
            onCell: () => ({
                style: {
                    maxWidth: 50,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                },
            }),
        },
        {
            title: 'KM',
            dataIndex: 'km',
            render: (_, record) => <span className="record-text">{record?.km}</span>,
            sorter: commonSorter('km'),
            width: 120,
        },
        {
            title: 'Month',
            dataIndex: 'months',
            render: (_, record) => <span className="record-text">{record?.months}</span>,
            sorter: commonSorter('months'),
            width: 120,
        },
    ];

    return (
        <>
            <div>
                {isEmpty ? (
                    <EmptyMasterData
                        onClickAdd={() => {
                            setIsModalAddState(true);
                            setIsModalOpen(true);
                        }}
                    />
                ) : (
                    <div>
                        <CommonTable
                            columns={columns}
                            dataSource={allKmMonthItems}
                            rowKey="id"
                            isLoading={isLoading}
                            onClickAdd={() => {
                                setIsModalAddState(true);
                                setIsModalOpen(true);
                            }}
                            onChange={onHandleSearch}
                            handleExcelData={handleExcelData}
                            resourceName={'KM/Month'}
                            onEdit={onHandleEditKmMonthItem}
                            onDelete={onHandleDeleteItem}
                            recomendedServicesArray={recomendedServicesData}
                            onHandleSelectedService={onHandleSelectedService}
                            selectedFilterValue={selectedFilter}
                            customSearchValue={customSearchValue}
                        />
                    </div>
                )}
                <div className="system-modal-parent">
                    <ModalMaster
                        openModal={isModalOpen}
                        closeModal={handleCancel}
                        label={ismodalAddState ? 'Add KM/Month' : 'Edit KM/Month Item'}
                        onClickSubmit={handleSubmit}
                        width={810}
                        isDisableOkButton={!ismodalAddState && disableSave}
                        buttonText={ismodalAddState ? 'Add' : 'Save'}
                        content={
                            <Form
                                name="wrap"
                                labelCol={{}}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                style={{ maxWidth: 810 }}
                                onFinish={handleSubmit}
                            >
                                <div className="form-inputs-parent">
                                    <Row gutter={16}>
                                        <Col>
                                            <Form.Item
                                                label="Recommended Service "
                                                required
                                                validateStatus={formErrors.recommendedService ? 'error' : ''}
                                                help={formErrors.recommendedService}
                                                style={{ width: '238px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Select
                                                    placeholder="Select Recommended Service"
                                                    onChange={onHandleRecomendedInputChange}
                                                    className="km-month-select"
                                                    value={formData?.recommendedService?.name || undefined}
                                                >
                                                    {recomendedServicesData.map((eachObject) => (
                                                        <Option key={eachObject.id} value={eachObject.id}>
                                                            {eachObject.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                label="Service Interval Code"
                                                required
                                                validateStatus={formErrors.serviceIntervalCode ? 'error' : ''}
                                                help={formErrors.serviceIntervalCode}
                                                style={{ width: '176px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                rules={[{ required: true, message: 'Enter Code' }]}
                                            >
                                                <Input
                                                    placeholder="Enter Code"
                                                    value={formData.serviceIntervalCode}
                                                    onChange={onHandleIntervalCodeInputChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                label="KM"
                                                required
                                                validateStatus={formErrors.km ? 'error' : ''}
                                                help={formErrors.km}
                                                style={{ width: '150px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <div className="sundry-parent-value-input">
                                                    <Input
                                                        placeholder="Enter KM"
                                                        value={formData.km}
                                                        onChange={onHandleKmInputChange}
                                                        className="value-input"
                                                        onBlur={onHandleKmBlur}
                                                    />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item
                                                label="Month"
                                                required
                                                validateStatus={formErrors.months ? 'error' : ''}
                                                help={formErrors.months}
                                                style={{ width: '150px' }}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <div className="sundry-parent-value-input">
                                                    <Input
                                                        placeholder="Enter Month"
                                                        value={formData.months}
                                                        onChange={onHandleMonthInputChange}
                                                        className="value-input"
                                                        onBlur={onHandleMonthsBlur}
                                                    />
                                                </div>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        label="Comments"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        validateStatus={formErrors.description ? 'error' : ''}
                                        help={formErrors.description}
                                    >
                                        <Input.TextArea
                                            placeholder="Enter Comments"
                                            value={formData.description}
                                            onChange={onHandleDescriptionChange}
                                        />
                                        {formErrors.showRemainingChar && (
                                            <span className="remaining-characters-style">{`Remaining ${formData.remainingCharlength} characters`}</span>
                                        )}
                                    </Form.Item>
                                </div>
                                <button ref={hiddenSubmitButton} type="submit" style={{ display: 'none' }} />
                            </Form>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default KmMonth;
