import { jsonFormat } from './jsonReverse';

const { calculations } = await import(`${process.env.REACT_APP_SERVER_URL}`);

export const calculatePartsAndLubricants = (partsData, staticRowsData, kmTitleData) => {
    const parsePrice = (price) => {
        return parseFloat(price) || 0;
    };

    const updatedStaticRowsData = staticRowsData.map((row) => {
        if (row.partsLubricants) {
            const kmIdPriceMap = {};
            kmTitleData.forEach((km) => {
                kmIdPriceMap[km.kmId] = 0;
            });

            partsData.forEach((part) => {
                part.kmsDetails.forEach((interval) => {
                    if (interval.isActive) {
                        kmIdPriceMap[interval.kmId] += parsePrice(interval.price);
                    }
                });
            });
            const updatedKmDetails = row?.kmsDetails?.map((kmDetail) => {
                const price = kmIdPriceMap[kmDetail.kmId] || 0;
                const formattedPrice = parseFloat(price.toFixed(2));

                return {
                    ...kmDetail,
                    price: formattedPrice,
                };
            });
            const updatedPartsLubricants = {
                ...row,
                kmsDetails: updatedKmDetails,
            };

            return updatedPartsLubricants;
        }

        return row;
    });

    return updatedStaticRowsData;
};

export const calculateSubtotalRows = (updatedStaticRowsData) => {
    let partsRows = [];
    let labourRows = [];
    let codeRows = [];
    let rsaRows = [];
    let subTotalRow = null;

    updatedStaticRowsData.forEach((row) => {
        if (row.partsLubricants) {
            partsRows.push(row);
        } else if (row.labour) {
            labourRows.push(row);
        } else if (row.code) {
            codeRows.push(row);
        } else if (row.rsa) {
            rsaRows.push(row);
        } else if (row.subTotal) {
            subTotalRow = row;
        }
    });

    let subtotalKmDetails = [];
    let allKmIds = new Set();

    [...partsRows, ...labourRows, ...codeRows, ...rsaRows].forEach((row) => {
        row.kmsDetails.forEach((detail) => {
            allKmIds.add(detail.kmId);
        });
    });

    allKmIds.forEach((kmId) => {
        let partsPrice = 0;
        let labourPrice = 0;
        let codePrice = 0;
        let rsaPrice = 0;

        partsRows.forEach((row) => {
            row.kmsDetails.forEach((detail) => {
                if (detail.kmId === kmId) {
                    partsPrice += Number(detail.price);
                }
            });
        });

        labourRows.forEach((row) => {
            row.kmsDetails.forEach((detail) => {
                if (detail.kmId === kmId) {
                    labourPrice += parseFloat(detail.price);
                }
            });
        });

        codeRows.forEach((row) => {
            row.kmsDetails.forEach((detail) => {
                if (detail.kmId === kmId) {
                    codePrice += parseFloat(detail.price);
                }
            });
        });

        rsaRows.forEach((row) => {
            row.kmsDetails.forEach((detail) => {
                if (detail.kmId === kmId) {
                    rsaPrice += parseFloat(detail.price);
                }
            });
        });

        let subtotalPrice = partsPrice + labourPrice + codePrice + rsaPrice;

        subtotalKmDetails.push({
            kmId: kmId,
            price: parseFloat(subtotalPrice.toFixed(2)),
        });
    });

    if (subTotalRow) {
        subtotalKmDetails.forEach((newDetail) => {
            let existingDetailIndex = subTotalRow.kmsDetails.findIndex((detail) => detail.kmId === newDetail.kmId);
            if (existingDetailIndex !== -1) {
                subTotalRow.kmsDetails[existingDetailIndex].price = newDetail.price;
            } else {
                subTotalRow.kmsDetails.push(newDetail);
            }
        });
    } else {
        subTotalRow = {
            subTotal: true,
            kmsDetails: subtotalKmDetails,
        };
    }
    let subTotalRowIndex = updatedStaticRowsData.findIndex((row) => row.subTotal);
    if (subTotalRowIndex !== -1) {
        updatedStaticRowsData[subTotalRowIndex] = subTotalRow;
    } else {
        updatedStaticRowsData.push(subTotalRow);
    }
    return updatedStaticRowsData;
};

export const calculateTax = (data) => {
    const convertedData = jsonFormat(data);
    const taxIntervals = convertedData.kmsDetails?.map((eachRow) => {
        return {
            kmId: eachRow?.kmId,
            price: calculations.calculateTax(eachRow?.subTotal, 10),
        };
    });
    const calculatedDataRows = data?.map((row) => {
        if (row.tax) {
            return { ...row, kmsDetails: taxIntervals };
        }
        return row;
    });
    return calculatedDataRows;
};

export const grandTotalCalulate = (data) => {
    const convertedData = jsonFormat(data);
    const grandTotalArray = convertedData?.kmsDetails?.map((eachRow) => {
        return {
            kmId: eachRow?.kmId,
            price: calculations.calculateGrandTotal(eachRow.subTotal, eachRow.tax),
        };
    });
    const calculatedDataRows = data.map((row) => {
        if (row?.grandTotal) {
            return { ...row, kmsDetails: grandTotalArray };
        }
        return row;
    });
    return calculatedDataRows;
};

export const calculateExcGST = (data) => {
    const convertedData = jsonFormat(data);
    const excGSTArray = convertedData?.kmsDetails?.map((eachRow) => {
        return {
            kmId: eachRow?.kmId,
            price: calculations.calculateExcludingGST(eachRow.totalIncGST),
        };
    });
    const calculatedDataRows = data.map((row) => {
        if (row?.totalExcGST) {
            return { ...row, kmsDetails: excGSTArray };
        }
        return row;
    });
    return calculatedDataRows;
};

export const calculateRebate = (data) => {
    const convertedData = jsonFormat(data);
    const rebateArray = convertedData?.kmsDetails?.map((eachRow) => {
        const price = calculations.calculateRebate(eachRow.subTotal, eachRow.totalExcGST);

        return {
            kmId: eachRow?.kmId,
            price: price < 0 ? 0 : price,
        };
    });

    const calculatedDataRows = data.map((row) => {
        if (row?.rebate) {
            return { ...row, kmsDetails: rebateArray };
        }
        return row;
    });
    return calculatedDataRows;
};

function sortByCodeAsc(array) {
    return array.slice().sort((a, b) => {
        const codeA = a.intervalcode.toUpperCase();
        const codeB = b.intervalcode.toUpperCase();
        if (codeA < codeB) return -1;
        if (codeA > codeB) return 1;
        return 0;
    });
}

export const calculate3YProgramme = (data) => {
    const convertedData = jsonFormat(data);
    let count = 0;
    const program3Years = convertedData?.kmsDetails?.map((eachRow) => {
        count += 1;
        if (count < 4) {
            const claimAmount = eachRow?.totalExcGST + eachRow.rebate;
            return { kmId: eachRow?.kmId, price: claimAmount };
        } else if (count > 5) {
            return { kmId: eachRow?.kmId, price: 0 };
        }
        return { kmId: eachRow?.kmId, price: eachRow.rebate };
    });
    const calculatedDataRows = data.map((row) => {
        if (row?.smp3) {
            return { ...row, kmsDetails: program3Years };
        }
        return row;
    });

    return calculatedDataRows;
};

export const calculate4YProgramme = (data) => {
    const convertedData = jsonFormat(data);
    let count = 0;
    const program4Years = convertedData?.kmsDetails?.map((eachRow) => {
        count += 1;
        const claimAmount = eachRow?.totalExcGST + eachRow.rebate;
        if (count < 5) {
            return { kmId: eachRow?.kmId, price: claimAmount };
        } else if (count > 5) {
            return { kmId: eachRow?.kmId, price: 0 };
        }
        return { kmId: eachRow?.kmId, price: eachRow.rebate };
    });
    const calculatedDataRows = data.map((row) => {
        if (row?.smp4) {
            return { ...row, kmsDetails: program4Years };
        }
        return row;
    });
    return calculatedDataRows;
};

export const calculate5YProgramme = (data) => {
    const convertedData = jsonFormat(data);
    let count = 0;
    const program5Years = convertedData?.kmsDetails?.map((eachRow) => {
        count += 1;
        const claimAmount = eachRow?.totalExcGST + eachRow.rebate;
        if (count <= 5) {
            return { kmId: eachRow?.kmId, price: claimAmount };
        } else if (count > 5) {
            return { kmId: eachRow?.kmId, price: 0 };
        }
    });
    const calculatedDataRows = data.map((row) => {
        if (row?.smp5) {
            return { ...row, kmsDetails: program5Years };
        }
        return row;
    });
    return calculatedDataRows;
};
