import React from 'react';
import { Button } from 'antd';
import './addButton.css';

const AddButton = ({ text, onClick, customHeight }) => {
    return (
        <Button
            style={{ height: customHeight }}
            onClick={onClick}
            type="primary"
            className="add-button-style"
        >{`Add ${text}`}</Button>
    );
};

export default AddButton;
