import { v4 as uuidv4 } from 'uuid';

export const transformData = (data) => {
    const { id, modelId, createdBy, updatedBy, createdAt, updatedAt, isActive, kmsDetails } = data.data;

    let transformedData = {
        id,
        modelId,
        createdBy,
        updatedBy,
        createdAt,
        updatedAt,
        isActive,
        parts: [],
        kmDetailsGsp: [],
        staticRows: [
            {
                id: uuidv4(),
                key: 'partsAndLubricants',
                partsLubricants: true,
                kmsDetails: [],
                expanded: false,
            },
            {
                id: uuidv4(),
                key: 'labour',
                labour: 'Labour',
                kmsDetails: [],
                expanded: false,
            },
            {
                id: uuidv4(),
                key: 'subTotal',
                subTotal: 'subTotal',
                kmsDetails: [],
                expanded: false,
            },
            { id: uuidv4(), key: 'tax', tax: 'tax', kmsDetails: [], expanded: false },
            {
                id: uuidv4(),
                key: 'grandTotal',
                grandTotal: 'GRAND TOTAL',
                kmsDetails: [],
            },
            {
                id: uuidv4(),
                key: 'totalIncGST',
                totalIncGST: 'totalIncGST',
                kmsDetails: [],
            },
            {
                id: uuidv4(),
                key: 'totalExcGST',
                totalExcGST: 'totalExcGST',
                kmsDetails: [],
            },
            { id: uuidv4(), key: 'rebate', rebate: 'Rebate', kmsDetails: [] },
            { id: uuidv4(), key: 'rsa', rsa: 'rsa', kmsDetails: [] },
            { id: uuidv4(), key: 'smp3', smp3: 'smp3', kmsDetails: [] },
            { id: uuidv4(), key: 'smp4', smp4: 'smp4', kmsDetails: [] },
            { id: uuidv4(), key: 'smp5', smp5: 'smp5', kmsDetails: [] },
        ],
    };

    const existingSundryKeys = new Set();
    kmsDetails.forEach((kmsDetail) => {
        const {
            kmId,
            code,
            partsAndLubricants,
            labourCost,
            subTotal,
            tax,
            grandTotal,
            totalIncGST,
            totalExcGST,
            rebate,
            rsa,
            serviceMarketingProgram3Y45K,
            serviceMarketingProgram4Y60K,
            serviceMarketingProgram5Y75K,
            parts,
            sundries,
        } = kmsDetail;
        if (!transformedData.kmDetailsGsp.find((km) => km.kmId === kmId && km.code === code)) {
            transformedData.kmDetailsGsp.push({
                kmId,
                code,
                isTitleChekBoxActive: false,
            });
        }
        transformedData.staticRows.forEach((row) => {
            switch (row.key) {
                case 'partsAndLubricants':
                    if (partsAndLubricants !== undefined) {
                        row.kmsDetails.push({ kmId, price: partsAndLubricants });
                    }
                    break;
                case 'labour':
                    if (labourCost !== undefined) {
                        row.kmsDetails.push({ kmId, price: labourCost });
                    }
                    break;
                case 'subTotal':
                    if (subTotal !== undefined) {
                        row.kmsDetails.push({ kmId, price: subTotal });
                    }
                    break;
                case 'tax':
                    if (tax !== undefined) {
                        row.kmsDetails.push({ kmId, price: tax });
                    }
                    break;
                case 'grandTotal':
                    if (grandTotal !== undefined) {
                        row.kmsDetails.push({ kmId, price: grandTotal });
                    }
                    break;
                case 'totalIncGST':
                    if (totalIncGST !== undefined) {
                        row.kmsDetails.push({ kmId, price: totalIncGST });
                    }
                    break;
                case 'totalExcGST':
                    if (totalExcGST !== undefined) {
                        row.kmsDetails.push({ kmId, price: totalExcGST });
                    }
                    break;
                case 'rebate':
                    if (rebate !== undefined) {
                        row.kmsDetails.push({ kmId, price: rebate });
                    }
                    break;
                case 'rsa':
                    if (rsa !== undefined) {
                        row.kmsDetails.push({ kmId, price: rsa });
                    }
                    break;
                case 'smp3':
                    if (serviceMarketingProgram3Y45K !== undefined) {
                        row.kmsDetails.push({ kmId, price: serviceMarketingProgram3Y45K });
                    }
                    break;
                case 'smp4':
                    if (serviceMarketingProgram4Y60K !== undefined) {
                        row.kmsDetails.push({ kmId, price: serviceMarketingProgram4Y60K });
                    }
                    break;
                case 'smp5':
                    if (serviceMarketingProgram5Y75K !== undefined) {
                        row.kmsDetails.push({ kmId, price: serviceMarketingProgram5Y75K });
                    }
                    break;
                default:
                    break;
            }
        });

        parts.forEach((part) => {
            const { id, partNumber, itemTechConfDetailId, price, name, qty, isChargesApplicable, isEditablePrice } =
                part;
            let existingPart = transformedData.parts.find(
                (p) => p.id === id && p.itemTechConfDetailId === itemTechConfDetailId
            );
            if (!existingPart) {
                existingPart = {
                    id,
                    partNumber,
                    itemTechConfDetailId,
                    isPartSelected: false,
                    name,
                    qty,
                    kmsDetails: [],
                };
                transformedData.parts.push(existingPart);
            }
            existingPart.kmsDetails.push({
                kmId,
                code,
                price: parseFloat(price),
                isActive: isChargesApplicable,
                basePrice: parseFloat(price),
            });
        });
        if (sundries && Array.isArray(sundries)) {
            let rowSpanCounter = sundries.length;
            sundries.forEach((sundry) => {
                if (!existingSundryKeys.has(sundry.code)) {
                    existingSundryKeys.add(sundry.code);
                    const sundryKmsDetails = kmsDetails.map((kmDetail) => ({
                        kmId: kmDetail.kmId,
                        price: sundry.value,
                    }));
                    const newRow = {
                        id: sundry.id,
                        key: sundry.code,
                        kmsDetails: sundryKmsDetails,
                        expanded: false,
                        rowSpan: rowSpanCounter--,
                        description: sundry.name,
                        code: sundry.code,
                        value: sundry.value,
                    };
                    const labourIndex = transformedData.staticRows.findIndex((row) => row.key === 'labour');
                    if (labourIndex !== -1) {
                        transformedData.staticRows.splice(labourIndex + 1, 0, newRow);
                    }
                }
            });
        }
    });

    return transformedData;
};
