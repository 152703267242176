import './NotFound.css';
import notFoundIcon from '../../Assets/Icons/Not_Found.svg';
import { Button, Image } from 'antd';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="not-found-bg">
            <h1 className="not-found-title-style">404 - Not Found</h1>
            <Image src={notFoundIcon} preview={false} className="nf-image" />
            <p>The page you are looking for does not exist.</p>
            <Link to="/">
                <Button type="primary">Home</Button>
            </Link>
        </div>
    );
};

export default NotFound;
