import React, { useEffect, useRef, useState } from 'react';
import { Modal, Tooltip, Button, Row, Spin } from 'antd';
import { commonSorter } from '../../CommonComponents/SortingLogic';
import EmptyMasterData from '../MasterData/EmptyMasterData/EmptyMasterData';
import CommonTable from '../../CommonComponents/Table/CommonTable';
import { formatDateTimeCustom } from '../../CommonComponents/Utils/dateTimeFormatter';
import apiService from '../../Services/commonServices';
import { listAuditReports, auditDetailLogs, filterAuditLogs } from '../../Services/Endpoints';
import './auditReporting.css';

import dayjs from 'dayjs';

const AuditReporting = () => {
    const [allAuditReportItems, setAllAuditReportItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [orginalAuditReportItems, setOriginalAuditReportItems] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [logsData, setLogsData] = useState(null);
    const [auditLogId, setAuditLogId] = useState();

    const getAuditDetailLogs = async (auditLogId) => {
        setLogsData(null);
        setIsLoading(true);
        try {
            const response = await apiService.get(auditDetailLogs.replace('{AuditLogId}', auditLogId));

            if (response) {
                setLogsData(response?.data);
                setSelectedModel(response?.data?.ModelData);
            }
        } catch (error) {
            console.error('error', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const filterAuditDetailLogs = async (feature, startDate, endDate, event) => {
        setLogsData(null);
        setIsLoading(true);
        try {
            const response = await apiService.get(
                filterAuditLogs
                    .replace('{feature}', feature)
                    .replace('{startDate}', startDate)
                    .replace('{endDate}', endDate)
                    .replace('{event}', event)
            );

            if (response) {
                setAllAuditReportItems(response?.data);
            }
        } catch (error) {
            console.error('error', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getAllAuditReportItems();
    }, []);

    useEffect(() => {
        getAuditDetailLogs(auditLogId);
    }, [auditLogId]);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleOk = () => {};

    const onHandleSearch = (e) => {
        const { value } = e.target || '';
        const filteredItems = orginalAuditReportItems?.filter((item) => {
            return (
                !value ||
                (item?.UserEmail && item.UserEmail?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.Action && item.Action?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.TableName && item.TableName?.toLowerCase().includes(value?.toLowerCase())) ||
                (formatDateTimeCustom(item?.CreatedOn) &&
                    formatDateTimeCustom(item?.CreatedOn)?.toLowerCase().includes(value?.toLowerCase()))
            );
        });
        setAllAuditReportItems(filteredItems);
    };

    const getAllAuditReportItems = async () => {
        try {
            const responseData = await apiService.get(listAuditReports);
            setAllAuditReportItems(responseData?.data);
            setOriginalAuditReportItems(responseData?.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    function formatDate(date) {
        if (!dayjs.isDayjs(date)) return null;

        return date.format('YYYY-MM-DD');
    }

    const onHandleFilter = (e) => {
        const validDate = e.date_range?.map((date) => dayjs(date));
        const formattedDate = validDate?.map((date) => formatDate(date));
        if (e.feature === 'All' && e.event === 'All' && formattedDate.length === 0) {
            getAllAuditReportItems();
        } else {
            filterAuditDetailLogs(e.feature, formattedDate[0], formattedDate[1], e.event);
        }
    };

    const handleExcelData = (json) => {};

    const columns = [
        {
            title: 'Date/Time',
            dataIndex: 'CreatedOn',
            render: (_, record) => <span className="record-text">{formatDateTimeCustom(record?.CreatedOn)}</span>,
            sorter: (a, b) => new Date(a.CreatedOn) - new Date(b.CreatedOn),
            width: '20%',
        },
        {
            title: 'User',
            dataIndex: 'UserEmail',
            render: (_, record) => <span className="record-text">{record?.UserEmail}</span>,
            sorter: commonSorter('UserEmail'),
            width: '30%',
        },
        {
            title: 'Feature',
            className: 'description-cell-style',
            dataIndex: 'TableName',
            render: (_, record) => (
                <Tooltip
                    height={500}
                    className="table-cell-tooltip-style"
                    placement="topLeft"
                    title={record?.TableName?.length > 70 ? record?.TableName : ''}
                >
                    <span className="record-text">
                        {record?.TableName.charAt(0).toUpperCase() + record?.TableName.slice(1)}
                    </span>
                </Tooltip>
            ),
            width: '30%',
        },
        {
            title: 'Event',
            dataIndex: 'Action',
            render: (_, record) => <span className="record-text">{record?.Action}</span>,
            width: '20%',
        },
    ];

    const handleView = async (record) => {
        setIsModalOpen(true);
        setAuditLogId(record?.AuditLogId);
    };

    const headerDetails = [
        { label: 'Model Type', prop: 'modelName' },
        { label: 'Engine Type', prop: 'engineNumber' },
        { label: 'SAP Code', prop: 'sapCode' },
        { label: 'Fuel Type', prop: 'engineType' },
        { label: 'Recommended Service', prop: 'recommendedServiceDetails' },
        { label: 'Variant', prop: 'modelVariant' },
        { label: 'GWMS Code', prop: 'gwmsServiceCode' },
    ];

    const ModelHeaderDetail = ({ label, value }) => (
        <div className="each-column">
            <p className="model-header-label">{label}</p>
            <span className="model-label-data">{value || '-'}</span>
        </div>
    );

    const getProperty = (object, path) => {
        const properties = path.split('.');
        return properties.reduce((obj, prop) => (obj && obj[prop] ? obj[prop] : null), object);
    };

    const renderData = (data) => {
        return Object.entries(data).map(([key, value]) => {
            const formattedValue =
                value === null
                    ? 'N/A'
                    : key === 'createdAt' || key === 'updatedAt'
                      ? formatDateTimeCustom(value)
                      : value;

            return (
                <pre style={{ margin: 0 }} key={key}>
                    <p style={{ margin: 0, padding: '0 15px' }}>
                        <span className="logs-property">{`${key.charAt(0).toUpperCase() + key.slice(1)}`}</span>
                        <span className="logs-value">{`: ${formattedValue}, `}</span>
                    </p>
                </pre>
            );
        });
    };

    const ChangedDataDetails = ({ logsData, loading }) => {
        const [logState, setLogsState] = React.useState({
            hasChanges: false,
            hasOldValues: false,
            hasNewValues: false,
            hasOldData: false,
            hasNewData: false,
        });

        useEffect(() => {
            if (logsData) {
                setLogsState({
                    hasChanges: logsData?.Changes && Object.keys(logsData.Changes).length > 0,
                    hasOldValues:
                        logsData?.Changes?.['Old Values'] && Object.keys(logsData.Changes['Old Values']).length > 0,
                    hasNewValues:
                        logsData?.Changes?.['New Values'] && Object.keys(logsData.Changes['New Values']).length > 0,
                    hasOldData: logsData?.OldData && Object.keys(logsData.OldData).length > 0,
                    hasNewData: logsData?.NewData && Object.keys(logsData.NewData).length > 0,
                });
            } else {
                setLogsState({
                    hasChanges: false,
                    hasOldValues: false,
                    hasNewValues: false,
                    hasOldData: false,
                    hasNewData: false,
                });
            }
        }, [logsData]);
        return (
            <div className="details-div">
                {loading ? (
                    <div className="spinner-cont">
                        <Spin size="small" />
                    </div>
                ) : (
                    <>
                        {!logState.hasChanges && !logState.hasNewData && !logState.hasOldData && (
                            <p className="no-logs-message">No logs available</p>
                        )}

                        {logState.hasOldValues && (
                            <div className="old-data-div">
                                <p className="old-title">Old Data</p>
                                <div className="old-content">{renderData(logsData.Changes['Old Values'])}</div>
                            </div>
                        )}

                        {logState.hasOldData && (
                            <div className="old-data-div">
                                <p className="old-title">Old Data</p>
                                <div className="old-content">{renderData(logsData.OldData)}</div>
                            </div>
                        )}

                        {logState.hasNewValues && (
                            <div className="new-data-div">
                                <p className="new-title">New Data</p>
                                <div className="new-content">{renderData(logsData.Changes['New Values'])}</div>
                            </div>
                        )}

                        {logState.hasNewData && (
                            <div className="new-data-div">
                                <p className="new-title">New Data</p>
                                <div className="new-content">{renderData(logsData.NewData)}</div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="audit-report-logs">
                <div>
                    <CommonTable
                        columns={columns}
                        dataSource={allAuditReportItems}
                        rowKey="id"
                        isLoading={isLoading}
                        onClickAdd={() => {
                            setIsModalOpen(true);
                        }}
                        onChange={onHandleSearch}
                        handleExcelData={handleExcelData}
                        filterData={onHandleFilter}
                        onView={handleView}
                        resourceName={'audit-reporting'}
                    />

                    <Modal
                        title={'Audit Log Details'}
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={900}
                        footer={[
                            <Button type="primary" onClick={handleCancel} className="audit-logs-btn">
                                Ok
                            </Button>,
                        ]}
                    >
                        <Row gutter={6} className="model-details-container">
                            {!isLoading &&
                                selectedModel &&
                                headerDetails.map((detail, index) => (
                                    <ModelHeaderDetail
                                        key={index}
                                        label={detail.label}
                                        value={getProperty(selectedModel, detail.prop)}
                                    />
                                ))}
                        </Row>
                        <Row>
                            <ChangedDataDetails logsData={logsData} loading={isLoading} />
                        </Row>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default AuditReporting;
