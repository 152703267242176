import React from 'react';
import EmptyIconSvg from '../../../Assets/Icons/Empty_Svg_Icon.svg';
import './ModelSelectionEmpty.css';

const EmptyModelTableContent = ({ text }) => (
    <div className="empty-main-bg">
        <div className="empty-main-content-bg">
            <img src={EmptyIconSvg} alt="empty" />
            <span className="empty-image-style ">{text}</span>
        </div>
    </div>
);
export default EmptyModelTableContent;
