import React from 'react';
import { Button, Image } from 'antd';
import './login.css';
import FaviconIcon from '../../Assets/Icons/Hyundai GSP_Logo 2.svg';
import microsoftIcon from '../../Assets/Icons/Microsoft.svg';

const LoginPage = () => {
    const handleLoginButton = async () => {
        window.location.href = `${process.env.REACT_APP_MICROSOFT_LOGIN_URL}/login`;
    };

    return (
        <div className="login-container">
            {/* <Image src={CoverPage} preview={false} className="image-coverpage"/> */}
            <div className="login-details-container">
                <img src={FaviconIcon} alt="Hyundai Logo" className="hyundai-logo" />
                <h1 className="welcome-text">Welcome to GSP</h1>
                <p className="p-text">Login to your GSP account</p>
                <Button className="login-button" onClick={handleLoginButton}>
                    <Image src={microsoftIcon} preview={false} className="img-microsoft-logo" />
                    <span className="btn-text">LOGIN WITH MICROSOFT</span>
                </Button>
                <p className="help-text">Need Help? Please contact GSP Support Team</p>
            </div>
        </div>
    );
};

export default LoginPage;
