import React, { useEffect, useRef, useState } from 'react';
import './category.css';
import { Input, Form, message, Row, Col, Tooltip, notification } from 'antd';
import { commonSorter } from '../../../CommonComponents/SortingLogic';
import EmptyMasterData from '../EmptyMasterData/EmptyMasterData';
import CommonTable from '../../../CommonComponents/Table/CommonTable';
import { listCategories } from '../../../Services/Endpoints';
import apiService from '../../../Services/commonServices';
import ModalMaster from '../../../CommonComponents/modals/Modal-Master/ModalMaster';
import { handleDescriptionInputChange, onHandleValueInputChange } from '../commonInputFunctions';

const Category = () => {
    const hiddenSubmitButton = useRef(null);
    const [isEmpty, setIsEmpty] = useState(false);
    const [allCategoryItems, setAllCategoryItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editCategoryItem, setEditCategoryItem] = useState({});
    const [ismodalAddState, setIsModalAddState] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [orginalCategoryItems, setOriginalCategoryItems] = useState([]);
    const [disableSave, setDisableSave] = useState(true);

    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });

    useEffect(() => {
        setIsLoading(true);
        getAllCategoryItems();
    }, []);

    const makeCategoryItemEmpty = () => {
        setFormData({
            name: '',
            description: '',
        });
    };

    const handleCancel = () => {
        setFormErrors(false);
        setIsModalOpen(false);
        setIsModalAddState(true);
        makeCategoryItemEmpty();
        setDisableSave(true);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            handleFinish();
        }
    };

    const validateForm = () => {
        const errors = {};

        if (!formData?.name) {
            errors.name = 'This field is required.';
        }

        const codeExists = allCategoryItems?.some(
            (item) =>
                item?.name?.trim()?.toLowerCase() === formData?.name?.trim()?.toLowerCase() &&
                item.id !== editCategoryItem.id
        );
        if (codeExists) {
            errors.name = 'Duplicate category name. Please enter a unique category name';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const onHandleNameInputChange = (e) => {
        setDisableSave(false);
        const { value } = e.target;

        if (value.trim().length === 0) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                name: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'This field is required.',
            }));
            return;
        }

        if (value.length > 50) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Max 50 characters.',
            }));
            return;
        }

        const codeExists = allCategoryItems?.some(
            (item) =>
                item?.name.trim()?.toLowerCase() === value.trim()?.toLowerCase() &&
                (!editCategoryItem || item.id !== editCategoryItem.id)
        );

        setFormData((prevFormData) => ({
            ...prevFormData,
            name: value,
        }));

        if (codeExists) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Duplicate category name. Please enter a unique category name.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                name: '',
            }));
        }
    };

    const onHandleDescriptionChange = (e) => {
        setDisableSave(false);
        handleDescriptionInputChange(e, setFormData, setFormErrors, setDisableSave, formErrors);
    };

    const onHandleSearch = (e) => {
        const { value } = e.target || '';
        const filteredItems = orginalCategoryItems?.filter((item) => {
            return (
                !value ||
                (item?.name && item.name?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.value && item.value?.toLowerCase().includes(value?.toLowerCase())) ||
                (item?.description && item.description?.toLowerCase().includes(value?.toLowerCase()))
            );
        });
        setAllCategoryItems(filteredItems);
    };

    const getAllCategoryItems = async () => {
        try {
            const responseData = await apiService.get(listCategories);
            setAllCategoryItems(responseData.data);
            setOriginalCategoryItems(responseData.data);
            setIsEmpty(responseData.data.length === 0);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    const onHandleAddCategoryItem = async (postNewCategoryItem) => {
        try {
            const response = await apiService.post(listCategories, postNewCategoryItem);
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        }
        getAllCategoryItems();
    };

    const onHandleEditCategoryItem = (record) => {
        setIsModalOpen(true);
        setIsModalAddState(false);
        setDisableSave(true);
        const editItem = allCategoryItems.find((eachObject) => eachObject.id === record?.id);
        setEditCategoryItem(editItem);
        setFormData({
            name: editItem.name,
            description: editItem.description,
        });
    };

    const onHandlevalueBlur = () => {
        const trimmedValue = formData?.value?.trim();

        if (trimmedValue === '') {
            setFormData((prevFormData) => ({
                ...prevFormData,
                value: '',
            }));
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                value: 'This field is required.',
            }));
        } else {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                value: '',
            }));
        }
    };

    const onHandleDescriptionBlur = () => {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            showRemainingChar: false,
        }));
    };

    const onHandleUpdateCategoryItem = async (updatedCategoryItem) => {
        try {
            const response = await apiService.put(`${listCategories}${editCategoryItem.id}`, updatedCategoryItem);
            message.success(response.message);
        } catch (error) {
            console.error('Error:', error.message);
            message.error(error.message);
        }
        getAllCategoryItems();
    };

    const handleFinish = () => {
        setIsModalOpen(false);
        setIsLoading(true);
        setFormErrors({});

        if (ismodalAddState) {
            const postNewCategoryItem = {
                ...formData,
                name: formData.name.trim(),
                description: formData.description.trim(),
            };

            onHandleAddCategoryItem(postNewCategoryItem);
        } else {
            const updatedItem = {
                ...editCategoryItem,
                name: formData.name.trim(),
                description: formData.description.trim(),
            };
            onHandleUpdateCategoryItem(updatedItem);
        }
        makeCategoryItemEmpty();
    };

    const onHandleDeleteItem = async (record) => {
        try {
            const response = await apiService.delete(`${listCategories}${record.id}`);
            notification.success({
                message: response.message,
            });
            getAllCategoryItems();
        } catch (error) {
            notification.error({
                message: error.message,
                duration: 5,
            });
        }
    };

    const handleExcelData = (json) => {};

    const columns = [
        {
            title: 'Category Name',
            dataIndex: 'name',
            render: (_, record) => <span className="record-text">{record?.name}</span>,
            sorter: commonSorter('name'),
            width: 300,
        },
        {
            title: 'Description',
            className: 'description-cell-style',
            dataIndex: 'description',
            render: (_, record) => (
                <Tooltip
                    height={500}
                    className="table-cell-tooltip-style"
                    placement="topLeft"
                    title={record?.description?.length > 70 ? record?.description : ''}
                >
                    <span className="record-text">{record?.description}</span>
                </Tooltip>
            ),
            width: 656,
        },
    ];

    return (
        <>
            <div>
                {isEmpty ? (
                    <EmptyMasterData
                        onClickAdd={() => {
                            setIsModalAddState(true);
                            setIsModalOpen(true);
                        }}
                    />
                ) : (
                    <div>
                        <CommonTable
                            columns={columns}
                            dataSource={allCategoryItems}
                            rowKey="id"
                            isLoading={isLoading}
                            onClickAdd={() => {
                                setIsModalAddState(true);
                                setIsModalOpen(true);
                            }}
                            onChange={onHandleSearch}
                            handleExcelData={handleExcelData}
                            onEdit={onHandleEditCategoryItem}
                            onDelete={onHandleDeleteItem}
                            resourceName={'Category'}
                        />
                    </div>
                )}
                <div className="system-modal-parent">
                    <ModalMaster
                        openModal={isModalOpen}
                        closeModal={handleCancel}
                        label={ismodalAddState ? 'Add Category' : 'Edit Category'}
                        onClickSubmit={handleSubmit}
                        width={566}
                        isDisableOkButton={!ismodalAddState && disableSave}
                        buttonText={ismodalAddState ? 'Add' : 'Save'}
                        content={
                            <Form
                                name="wrap"
                                labelCol={{}}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                style={{ maxWidth: 600 }}
                                onFinish={handleSubmit}
                            >
                                <div className="form-inputs-parent">
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Category Name"
                                                required
                                                validateStatus={formErrors.name ? 'error' : ''}
                                                help={formErrors.name}
                                                style={{}}
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                            >
                                                <Input
                                                    placeholder="Enter Category Name"
                                                    value={formData.name}
                                                    onChange={onHandleNameInputChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Description"
                                                labelCol={{ span: 24 }}
                                                wrapperCol={{ span: 24 }}
                                                validateStatus={formErrors.description ? 'error' : ''}
                                                help={formErrors.description}
                                            >
                                                <Input.TextArea
                                                    placeholder="Enter Description"
                                                    value={formData.description}
                                                    onChange={onHandleDescriptionChange}
                                                />
                                                {formErrors.showRemainingChar && (
                                                    <span className="remaining-characters-style">{`Remaining ${formData.remainingCharlength} characters`}</span>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <button ref={hiddenSubmitButton} type="submit" style={{ display: 'none' }} />
                            </Form>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default Category;
