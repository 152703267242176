import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import React, { Suspense, useEffect } from 'react';
import Home from '../Components/Home/Home';
import KmMonth from '../Components/MasterData/Km-Month/KmMonth';
import Model from '../Components/MasterData/Model/Model';
import System from '../Components/MasterData/System/System';
import Parts from '../Components/MasterData/Parts/Parts';
import LabourRates from '../Components/MasterData/LabourRates/LabourRates';
import Sundry from '../Components/MasterData/Sundry/Sundry';
import Category from '../Components/MasterData/Category/Category';
import ModelServiceInterval from '../Components/ModelServiceInterval/ModelServiceInterval';
import GspOverview from '../Components/GSP-Overview/GSP-Overview';
import GspConfiguration from '../Components/GSP-Configuration/GSP-Configuration';
import TechnicalConfiguration from '../Components/techincal-Configuration/technical-Configuration';
import NotFound from '../Components/Not-Found/NotFound';
import LoginPage from '../Components/Login/login';
import ProtectedRoute from './protectedRoute';
import Main from '../Components/Main/Main';
import HomeSkelton from '../Components/Home/HomeSkelton';
import { auth } from '../Services/authService';
import Notification from '../Components/Notification-Preferences/Notification-Preferences';
import AuditReporting from '../Components/Audit-Reporting/AuditReporting';

const LazyHome = React.lazy(() => import('../Components/Home/Home'));

function RouteContent() {
    useEffect(() => {
        if (auth.getToken()) {
            const intervalId = setInterval(() => {
                const isAuthenticated = auth.check();
                if (!isAuthenticated) {
                    window.location.href = '/login';
                }
            }, 30000);

            return () => clearInterval(intervalId);
        }
    }, []);

    return (
        <>
            <Routes>
                <Route path="/app/login" element={<Main />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<ProtectedRoute Component={<Outlet />} />}>
                    <Route
                        path="/"
                        element={
                            <Suspense fallback={<HomeSkelton />}>
                                <LazyHome />
                            </Suspense>
                        }
                    />
                    <Route path="/system" element={<System />} />
                    <Route path="/km-month" element={<KmMonth />} />
                    <Route path="/models" element={<Model />} />
                    <Route path="/parts" element={<Parts />} />
                    <Route path="/labour-rate" element={<LabourRates />} />
                    <Route path="/sundry" element={<Sundry />} />
                    <Route path="/category" element={<Category />} />
                    <Route path="/model-service-interval" element={<ModelServiceInterval />} />
                    <Route path="/model-service-interval/:id" element={<ModelServiceInterval />} />
                    <Route path="/technical-configuration" element={<TechnicalConfiguration />} />
                    <Route path="/technical-configuration/:id" element={<TechnicalConfiguration />} />
                    <Route path="/gsp-configuration" element={<GspConfiguration />} />
                    <Route path="/gsp-configuration/:id" element={<GspConfiguration />} />
                    <Route path="/gsp-overview" element={<GspOverview />} />
                    <Route path="/notification-preferences" element={<Notification />} />
                    <Route path="/audit-reporting" element={<AuditReporting />} />
                    <Route path="/not-found" element={<NotFound />} />
                    <Route path="*" element={<Navigate to="/not-found" />} />
                </Route>
            </Routes>
        </>
    );
}

export default RouteContent;
