import './emptyMasterData.css';
import EmptyIconSvg from '../../../Assets/Icons/Empty_Svg_Icon.svg';

import AddButton from '../../../CommonComponents/Add-Button/AddButton';
import CommonImportBtn from '../../../CommonComponents/Common-Import-Btn/CommonImportBtn';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const EmptyMasterData = ({ onClickAdd }) => {
    const [showImportButton, setShowImportButton] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname;

    const buttonText = () => {
        switch (currentPath) {
            case '/system':
                return 'System item';
            case '/km-month':
                return 'KM/Month';
            case '/models':
                return 'Models';
            case '/parts':
                return 'Parts';
            case '/labour-rate':
                return 'Labour Rate';
            case '/sundry':
                return 'Sundry';
            case '/category':
                return 'Category';
            case '/notification-preferences':
                return 'Recipient';
            default:
                return '';
        }
    };

    return (
        <div className="empty-bg">
            <img src={EmptyIconSvg} alt="empty" />
            <p>
                {currentPath === '/notification-preferences'
                    ? 'It appears there are no recipients added to the notification receivers list'
                    : `It appears there are no ${
                          currentPath === '/sundry'
                              ? 'sundrie'
                              : currentPath === '/category'
                                ? 'Categorie'
                                : buttonText()
                      }s to display at the moment!`}
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {showImportButton && <CommonImportBtn />}
                <AddButton text={buttonText()} onClick={onClickAdd} />
            </div>
        </div>
    );
};

export default EmptyMasterData;
