import { Link } from 'react-router-dom';

export const RedirectTechDiv = ({ model }) => {
    return (
        <div>
            <div
                style={{
                    marginTop: 24,
                }}
            >
                <div className="tech-header">
                    <div className="dv-message">
                        Parts and Labour Action information for this model have not been configured on the Technical
                        Configuration page. As a result, no information related to Parts and Labour Action can be viewed
                        on this page. Please complete the configuration for this model on the Technical Configuration
                        page to display the respective information. Please follow the link-{' '}
                        <Link to={`/technical-configuration/${model?.id}`}>
                            {/* {`/technical-configuration/${model?.id}`} */}
                            "Technical Configuration"
                        </Link>{' '}
                        to configure Technical Configuration
                    </div>
                </div>
            </div>
        </div>
    );
};

export const RedirectModelServiceDiv = ({ model }) => {
    return (
        <div>
            <div
                style={{
                    marginTop: 24,
                }}
            >
                <div className="tech-header">
                    <div className="dv-message">
                        Service intervals for this model have not been configured on the Model Service Interval page. As
                        a result, no service intervals can be viewed on this page. Please complete the configuration for
                        this model on the Model Service Interval page to display the respective service intervals.
                        Please follow the link-{' '}
                        <Link to={`/model-service-interval/${model?.id}`}>
                            {/* {`/model-service-interval/${model?.id} `} */}
                            “Model Service Interval”
                        </Link>
                        to configure Model Service Interval
                    </div>
                </div>
            </div>
        </div>
    );
};
