import { Spin, Table, Button, Select, Switch } from 'antd';
import './commonTable.css';
import AddButton from '../Add-Button/AddButton';
import CommonImportBtn from '../Common-Import-Btn/CommonImportBtn';
import CustomSearch from '../Custom-Search/CustomSearch';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CustomFilter } from '../../CommonComponents/filter/filter';
import { Tooltip, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined, EyeFilled } from '@ant-design/icons';
import CustomButton from '../Button/CustomButton';
import ExportDropdown from '../menu/export-menu';
import GSPOverviewIcon from '../../Assets/Icons/gsp-overview-export.svg';
import ExportLogsIcon from '../../Assets/Icons/HomeMasterCardIcons/Export Log Report Icon.svg';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

const CommonTable = ({
    dataSource,
    columns,
    rowKey,
    onSearch,
    onChange,
    onClickAdd,
    isLoading,
    handleExcelData,
    recordsState,
    resourceName,
    onEdit,
    onDelete,
    onToggleNotification,
    filterData,
    uniqueSapCodes,
    allEngineType,
    modelDetails,
    recomendedServicesArray,
    onHandleSelectedService,
    selectedFilterValue,
    customSearchValue,
    selectedFormat,
    onView,
    onExport,
}) => {
    const [showPagination, setShowPagination] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [SelectedExport, setSelectedExport] = useState(null);

    const location = useLocation();
    const currentPath = location.pathname;

    const handlePageSizeChange = (current, size, e) => {
        setPageSize(size);
    };

    useEffect(() => {
        if (dataSource?.length >= 15) {
            setShowPagination(true);
        } else {
            setShowPagination(false);
        }
    }, [dataSource]);

    const buttonText = () => {
        switch (currentPath) {
            case '/system':
                return 'System item';
            case '/km-month':
                return 'KM/Month';
            case '/models':
                return 'Model';
            case '/parts':
                return 'Part';
            case '/labour-rate':
                return 'Labour Rate';
            case '/sundry':
                return 'Sundry';
            case '/category':
                return 'Category';
            case '/notification-preferences':
                return 'Recipient';
            default:
                return currentPath;
        }
    };

    const deleteRecord = (record) => {
        let deleteRecordName;

        switch (resourceName) {
            case 'Model':
                deleteRecordName = record.modelName;
                break;
            case 'Sundry':
                deleteRecordName = record.code;
                break;
            case 'Part':
                deleteRecordName = record.itemCode;
                break;
            case 'KM/Month':
                deleteRecordName = record.serviceIntervalCode;
                break;
            case 'Labour Rate':
                deleteRecordName = record.rateType;
                break;
            case 'Category':
                deleteRecordName = record.name;
                break;
            case 'Recipient':
                deleteRecordName = record.name;
                break;
            default:
                deleteRecordName = record.name;
                break;
        }

        Modal.confirm({
            title: `Delete ${resourceName}`,
            className: 'delete-popup',
            icon: <DeleteOutlined />,
            content: `Are you sure you want to delete this selected ${resourceName} '${deleteRecordName}'?`,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk() {
                onDelete(record);
            },
        });
    };

    const handleExportSelection = (key) => {
        setSelectedExport(key);
        selectedFormat(key);
        console.log(`Selected export option in parent: ${key}`);
    };

    const actionColumn = {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) => (
            <div className="master-actions">
                <Tooltip title="Edit">
                    <Button
                        onClick={() => onEdit(record)}
                        shape="square"
                        className="actions-edit"
                        icon={<EditOutlined />}
                        disabled={currentPath === '/notification-preferences' && recordsState[record.id]}
                    />
                </Tooltip>
                {currentPath === '/notification-preferences' ? (
                    <>
                        <Tooltip
                            placement="topRight"
                            title={
                                recordsState[record.id]
                                    ? 'Click to remove recipient from Notification receivers list'
                                    : 'Click to add recipient to Notification receivers list'
                            }
                        >
                            <Switch
                                className="action-switch"
                                checked={recordsState[record.id]}
                                onChange={(checked) => onToggleNotification(record, checked)}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Button
                                shape="square"
                                className="actions-edit"
                                onClick={() => deleteRecord(record)}
                                icon={<DeleteOutlined style={{ color: '#002c5f' }} />}
                            />
                        </Tooltip>
                    </>
                ) : (
                    <Tooltip title="Delete">
                        <Button
                            shape="square"
                            className="actions-edit"
                            onClick={() => deleteRecord(record)}
                            icon={<DeleteOutlined style={{ color: '#002c5f' }} />}
                        />
                    </Tooltip>
                )}
            </div>
        ),
        width: 80,
    };

    const acionColumnGSPOverview = {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) => (
            <div className="master-actions">
                <Tooltip title=" Detailed View">
                    <Button
                        onClick={() => onView(record)}
                        shape="square"
                        className="actions-edit"
                        icon={<EyeOutlined />}
                    />
                </Tooltip>
                <Tooltip title="Export to Infomedia">
                    <Button
                        shape="square"
                        className="actions-edit"
                        onClick={() => {
                            onExport(record, 'Infomedia');
                        }}
                        icon={<img src={GSPOverviewIcon} alt="empty" />}
                    />
                </Tooltip>
                <Tooltip title="Export to Overview">
                    <Button
                        shape="square"
                        className="actions-edit"
                        onClick={() => {
                            onExport(record, 'Overview');
                        }}
                        icon={<img src={GSPOverviewIcon} alt="empty" />}
                    />
                </Tooltip>
            </div>
        ),
        width: 80,
    };

    const acionColumnAuditReport = {
        title: 'Action',
        dataIndex: 'action',
        render: (_, record) => (
            <div className="master-actions">
                <Tooltip title=" Detailed View">
                    <Button
                        onClick={() => onView(record)}
                        shape="square"
                        className="actions-edit"
                        icon={<EyeFilled />}
                    />
                </Tooltip>
            </div>
        ),
        width: 80,
    };

    const allColumns = [...columns, actionColumn];
    const overviewColoumn = [...columns, acionColumnGSPOverview];
    const auditReportColumn = [...columns, acionColumnAuditReport];

    const handleFilterData = (e) => {
        filterData(e);
    };

    return (
        <div className="result-table-main-bg">
            <div className="table-buttons-container">
                <div>
                    <span className="total-applied-intervals">Total : </span>
                    <span className="no-of-intrervals">
                        {dataSource?.length === 1 ? `${dataSource?.length} Item` : `${dataSource?.length} Items`}
                    </span>
                </div>
                <div className="add-import-search-container">
                    {resourceName === 'KM/Month' && (
                        <Select
                            placeholder="Select Recommended Service"
                            className="style-filter-box"
                            allowClear
                            onChange={onHandleSelectedService}
                            value={selectedFilterValue}
                        >
                            {recomendedServicesArray?.map((eachObject) => (
                                <Option key={eachObject.id} value={eachObject.id}>
                                    {eachObject.name}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <CustomSearch onSearch={onSearch} onChange={onChange} customSearchValue={customSearchValue} />
                    {resourceName === 'Model' ||
                    resourceName === 'gsp-overview' ||
                    resourceName === 'audit-reporting' ? (
                        <CustomFilter
                            filterData={handleFilterData}
                            uniqueSapCodes={uniqueSapCodes}
                            allEngineType={allEngineType}
                            resourceName={resourceName}
                            modelDetail={modelDetails}
                        />
                    ) : null}
                    {/* <CommonImportBtn
            customHeight={"32px"}
            handleExcelData={handleExcelData}
          /> */}
                    {resourceName === 'gsp-overview' ? (
                        <ExportDropdown onExportSelect={handleExportSelection} />
                    ) : resourceName === 'audit-reporting' ? (
                        <Button
                            type="primary"
                            style={{ height: '32px' }}
                            icon={<img src={ExportLogsIcon} alt="empty" />}
                        >
                            Export Logs Report
                        </Button>
                    ) : (
                        <AddButton text={buttonText()} customHeight={'32px'} onClick={onClickAdd} />
                    )}
                </div>
            </div>
            <div className="table-style">
                <Table
                    className="table-class-name"
                    pagination={{
                        onChange: handlePageSizeChange,
                        pageSizeOptions: ['10', '15', '30', '50', '100'],
                        pageSize: pageSize,
                        showSizeChanger: true,
                    }}
                    bordered={true}
                    columns={
                        resourceName === 'gsp-overview'
                            ? overviewColoumn
                            : resourceName === 'audit-reporting'
                              ? auditReportColumn
                              : allColumns
                    }
                    dataSource={dataSource}
                    rowKey={rowKey}
                    scroll={{ x: '100%' }}
                    loading={isLoading}
                />
            </div>
        </div>
    );
};

export default CommonTable;
