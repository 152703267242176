import { Button, Modal } from 'antd';
import './modalMaster.css';

const ModalMaster = ({
    openModal,
    closeModal,
    onClickSubmit,
    content,
    label,
    width,
    buttonText,
    isDisableOkButton,
}) => {
    return (
        <Modal
            title={label}
            open={openModal}
            onCancel={closeModal}
            width={width}
            style={{ padding: '0px' }}
            className="master-modal-parent"
            footer={[
                <Button key="cancel" onClick={closeModal}>
                    Cancel
                </Button>,
                <Button disabled={isDisableOkButton} key="add" type="primary" onClick={onClickSubmit}>
                    {buttonText}
                </Button>,
            ]}
        >
            {content}
        </Modal>
    );
};

export default ModalMaster;
