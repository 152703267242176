import React from 'react';
import { Modal, Button, Table, Tooltip, Tag, Space, Typography } from 'antd';
import './gspSyncModal.css';

const SyncModal = ({ visible, data, onConfirm, onCancel }) => {
    const kmsKeys = Object.keys(data);
    const kmsColumns = kmsKeys.map((key) => {
        return {
            title: data[key].kmCode,
            key: key,
            dataIndex: key,
            render: ([newVal, oldVal]) => (
                <div>
                    <Text style={{ color: 'green' }}>{newVal}</Text>{' '}
                    <Text style={{ color: 'red', fontSize: '10px' }}>{oldVal}</Text>
                </div>
            ),
        };
    });
    const fixedColumns = [
        {
            title: 'Part Number',
            dataIndex: 'partNumber',
            key: 'partNumber',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <Tag color={status === 'new' ? 'green' : 'blue'}>{status.toUpperCase()}</Tag>,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: ([newVal, oldVal]) => (
                <div>
                    <Text style={{ color: 'green' }}>{newVal}</Text>{' '}
                    <Text style={{ color: 'red', fontSize: '10px' }}>{oldVal}</Text>
                </div>
            ),
        },
    ];

    const columns = [...fixedColumns, ...kmsColumns];
    const setPrice = (partKey) => {
        const tempData = { ...data };
        const kmsData = { ...data };
        for (let [key, value] of Object.entries(tempData)) {
            let currentPart = value.parts.filter((part) => {
                return `${part.id}-${part.itemTechConfDetailId}` == partKey;
            });
            let part = currentPart[0];
            kmsData[key] = [`${part.price.updated}`, `${part.price.orignal ? part.price.orignal : ''}`];
        }
        return kmsData;
    };
    const { Text } = Typography;
    const dataSource = data[kmsKeys[0]].parts.map((part) => {
        let price = setPrice(`${part.id}-${part.itemTechConfDetailId}`);
        return {
            key: `${part.id}-${part.itemTechConfDetailId}`,
            partNumber: part.partNumber,
            description: part.description,
            status: part.status,
            quantity: [`${part.qty.updated}`, `${part.qty.orignal ? part.qty.orignal : ''}`],
            ...price,
        };
    });

    return (
        <>
            <Modal
                className="sync-gsp-modal"
                style={{
                    maxHeight: '500px',
                    overflowY: 'hidden',
                }}
                width={'max-content'}
                open={visible}
                title="Sync Data"
                onCancel={onCancel}
                footer={[
                    <Button key="cancel" onClick={onCancel}>
                        Cancel
                    </Button>,
                    <Button key="confirm" type="primary" onClick={onConfirm}>
                        Confirm
                    </Button>,
                ]}
            >
                <Table
                    style={{
                        maxHeight: '400px',
                        overflow: 'scroll',
                        scrollBehavior: 'smooth',
                        width: 'max-content',
                    }}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                />
            </Modal>
        </>
    );
};

export default SyncModal;
