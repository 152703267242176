import React from 'react';
import './commonImportBtn.css';
import { Button } from 'antd';
import * as XLSX from 'xlsx';

const CommonImportBtn = ({ customHeight, handleExcelData }) => {
    const handleImport = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.onchange = (e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                handleExcelData(jsonData);
            };
            reader.readAsArrayBuffer(file);
        };
        input.click();
    };

    return (
        <Button style={{ height: customHeight }} className="import-style" onClick={handleImport}>
            Import File
        </Button>
    );
};

export default CommonImportBtn;
