export const listModels = '/models/';
export const modelDetails = '/models/';
export const listIntervals = '/kilometers/';
export const listLabourRate = '/labour-rates/';
export const listRSAdealerPayments = '/sundries/';
export const listCategories = '/technical-configuration-category/';
export const listRecipients = '/notification-preferences/';
export const listAuditReports = '/audit-report-logs';
export const listModelsForDuplicate = '/models/?has_technical_configuration=true&modelId=';

export const listSystemItems = '/configurations/';
export const listParts = '/items/';
export const deleteSystemItem = '/configurations/?id=';
export const deleteSundryItem = '/sundries/?id=';
export const deleteKilomenters = '/kilometers/?id=';
export const changeRecipientStatus = '/change-status/';

export const recomendedServices = '/recommended-services/';
export const fuelTypes = '/engine-types/';
export const engineType = '/engine-types/';
export const sapCode = '/models/list-sap-code/';
export const RecommendedServices = '/recommended-services/';
export const editModel = '/models/{id}';

export const getLabourRatesByFuelType = '/labour-rates/engine-type/';
export const serviceIntervalsApi = '/service-intervals/';
export const getRecomendedKmMonths = '/kilometers/recommended-service/';
export const technicalConfiguration = '/technical-configuration/';
export const duplicateTechnicalConfiguration = '/technical-configuration?modelId=';
export const exportTechnicalConfiguration = '/technical-configuration/export/{modelId}';
export const auditDetailLogs = '/audit-report-logs/{AuditLogId}';
export const filterAuditLogs = '/audit-report-logs?table={feature}&createdOn={startDate},{endDate}&event={event}';

export const getGspConfigurations = '/gsp-configuration/gsp-configuration-parts/';
export const gspConfiguration = '/gsp-configuration/';

export const getGSPOverViewModelList = '/overview/';
export const profile = '/login/profile';
export const exportGspOverview = '/overview/export';
export const syncTechnicalConfiguration = '/sync-technical-configuration';
