import './App.css';
import RouteContent from './Routes/Routes.jsx';

function App() {
    return (
        <>
            <RouteContent />
        </>
    );
}

export default App;
