export const commonSorter = (dataIndex) => (a, b) => {
    const valueA = a[dataIndex];
    const valueB = b[dataIndex];

    // Parse values as floats if they are strings and contain a valid number
    const parsedValueA = typeof valueA === 'string' && !isNaN(parseFloat(valueA)) ? parseFloat(valueA) : valueA;
    const parsedValueB = typeof valueB === 'string' && !isNaN(parseFloat(valueB)) ? parseFloat(valueB) : valueB;

    // Compare the parsed values
    if (typeof parsedValueA === 'number' && typeof parsedValueB === 'number') {
        return parsedValueA - parsedValueB;
    }

    // If one of the values couldn't be parsed as a number, prioritize numeric values
    if (typeof parsedValueA === 'number' || typeof parsedValueB === 'number') {
        return typeof parsedValueA === 'number' ? -1 : 1;
    }

    // If both values are strings or couldn't be parsed as numbers, sort them using localeCompare
    if (typeof parsedValueA === 'string' && typeof parsedValueB === 'string') {
        return parsedValueA.localeCompare(parsedValueB);
    }

    // Default case: return 0
    return 0;
};

export const alphanumericSorter = (key) => (a, b) => {
    const valueA = a[key]?.toString() || '';
    const valueB = b[key]?.toString() || '';
    return valueA.localeCompare(valueB, undefined, {
        numeric: true,
        sensitivity: 'base',
    });
};

// utils/modelSorting.js

// export const commonAlphanumericSorter = (array, key) => {
//   const sortedItems = array.slice().sort((a, b) => {
//     const extractParts = (modelName) => {
//       if (!modelName) return [''];
//       const parts = modelName.match(/([a-zA-Z]+)|(\d+)/g) || [];
//       return parts.map(part => isNaN(part) ? part.toLowerCase() : parseInt(part, 10));
//     };

//     const partsA = extractParts(a[key]);
//     const partsB = extractParts(b[key]);

//     for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
//       if (partsA[i] !== partsB[i]) {
//         if (isNaN(partsA[i]) && isNaN(partsB[i])) {
//           return partsA[i].localeCompare(partsB[i]);
//         } else if (isNaN(partsA[i])) {
//           return -1;
//         } else if (isNaN(partsB[i])) {
//           return 1;
//         } else {
//           return partsA[i] - partsB[i];
//         }
//       }
//     }
//     return partsA.length - partsB.length;
//   });

//   return sortedItems;
// };

export const commonAlphanumericSorter = (items, key) => {
    const sortAlphaNumeric = (a, b) => {
        const extractParts = (value) => {
            if (!value) return [''];
            const alphaParts = value.toString().match(/[a-zA-Z]+/g) || [];
            const numericParts = value.toString().match(/\d+/g) || [];
            const parts = alphaParts.concat(numericParts);

            return parts.map((part) => (isNaN(part) ? part?.toLowerCase() : parseInt(part, 10)));
        };

        const valueA = a[key];
        const valueB = b[key];
        if (valueA === null || valueA === undefined) return -1;
        if (valueB === null || valueB === undefined) return 1;

        const partsA = extractParts(valueA);
        const partsB = extractParts(valueB);

        for (let i = 0; i < Math.min(partsA.length, partsB.length); i++) {
            if (partsA[i] !== partsB[i]) {
                if (isNaN(partsA[i]) && isNaN(partsB[i])) {
                    return partsA[i].localeCompare(partsB[i]);
                } else if (isNaN(partsA[i])) {
                    return -1;
                } else if (isNaN(partsB[i])) {
                    return 1;
                } else {
                    return partsA[i] - partsB[i];
                }
            }
        }

        return partsA.length - partsB.length;
    };

    return items.slice().sort(sortAlphaNumeric);
};
