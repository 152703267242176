import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { sideNavCollapsedState } from '../../Recoil/recoilState';
import HyundaiVerticalLogo from '../../Assets/Icons/Hyundai_Vertical Logo.svg';
import ServiceIntervalIcon from '../../Assets/Icons/Service Interval.svg';
import ServiceIntervalIconWhiteSvg from '../../Assets/Icons/HomeMasterCardIcons/ServiceIntervalWhite.svg';
import NotificationPreferencesWhiteSvg from '../../Assets/Icons/HomeMasterCardIcons/Notification Preference White.svg';
import NotificationPreferencesSvg from '../../Assets/Icons/HomeMasterCardIcons/Notification Preference.svg';
import AuditReportingBlackSvg from '../../Assets/Icons/HomeMasterCardIcons/Audit Report Black.svg';
import AuditReportingWhiteSvg from '../../Assets/Icons/HomeMasterCardIcons/Audit Report White.svg';
import HyundaiLogo from '../../Assets/Icons/Hyundai GSP_Logo 2.svg';

import './sideMenu.css';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ControlOutlined,
    UnorderedListOutlined,
    HomeOutlined,
    BarChartOutlined,
    ReconciliationOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, Space } from 'antd';

const { Sider } = Layout;

const SideMenu = () => {
    const [collapsed, setCollapsed] = useRecoilState(sideNavCollapsedState);

    const [disableButton] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(location.pathname);

    useEffect(() => {
        const newPath = location.pathname.split('/');
        let selectedKey = '/';
        if (newPath.length > 1) {
            selectedKey += newPath[1];
        }
        setSelectedKey(selectedKey);
    }, [location.pathname]);

    const menuItems = [
        {
            key: '/',
            icon: <HomeOutlined />,
            label: 'Home',
        },
        {
            key: '/master-data',
            icon: <ReconciliationOutlined />,
            label: 'Master Data',
            children: [
                { label: 'Model', key: '/models' },
                { label: 'KM/Month', key: '/km-month' },
                { label: 'Labour Rate', key: '/labour-rate' },
                { label: 'Parts', key: '/parts' },
                { label: 'Sundry', key: '/sundry' },
                { label: 'System', key: '/system' },
                { label: 'Category Master', key: '/category' },
            ],
        },
        {
            key: '/model-service-interval',
            icon:
                selectedKey === '/model-service-interval' ? (
                    <img alt="icon" src={ServiceIntervalIconWhiteSvg} />
                ) : (
                    <img alt="icon" src={ServiceIntervalIcon} />
                ),
            label: 'Model Service Interval',
        },
        {
            key: '/technical-configuration',
            icon: <UnorderedListOutlined />,
            label: 'Technical Configuration',
        },
        {
            key: '/gsp-configuration',
            icon: <ControlOutlined />,
            label: 'GSP Configuration',
        },
        {
            key: '/gsp-overview',
            icon: <BarChartOutlined />,
            label: 'GSP Overview',
        },
        {
            key: '/notification-preferences',
            icon:
                selectedKey === '/notification-preferences' ? (
                    <img alt="icon" src={NotificationPreferencesWhiteSvg} />
                ) : (
                    <img alt="icon" src={NotificationPreferencesSvg} />
                ),
            label: 'Notification Preferences',
        },
        {
            key: '/audit-reporting',
            icon:
                selectedKey === '/audit-reporting' ? (
                    <img alt="icon" src={AuditReportingWhiteSvg} />
                ) : (
                    <img alt="icon" src={AuditReportingBlackSvg} />
                ),
            label: 'Audit & Reporting',
        },
    ];

    return (
        <Space className="sider-container" align="start">
            <Sider width={collapsed ? '72px' : '264px'} trigger={null} collapsible collapsed={collapsed}>
                <Link to="/">
                    <div className="logo-container">
                        <img
                            alt="logo"
                            src={collapsed ? HyundaiVerticalLogo : HyundaiLogo}
                            className={`logo-transform ${collapsed ? 'collapsed' : 'expanded'}`}
                        />
                    </div>
                </Link>
                {!disableButton && (
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 48,
                            height: 40,
                            marginLeft: collapsed ? '16.5px' : '214px',
                            borderRadius: '4px',
                            marginBottom: '6px',
                            marginTop: '4px',
                        }}
                    />
                )}
                <div className="sider-menu-container">
                    <Menu
                        onClick={({ key }) => {
                            navigate(key);
                            setSelectedKey(key);
                            setCollapsed(true);
                        }}
                        theme="dark"
                        mode="inline"
                        style={{
                            backgroundColor: '#F2E5D8',
                            paddingLeft: collapsed && '12px',
                            paddingRight: collapsed && '12px',
                        }}
                        defaultSelectedKeys={['/']}
                        selectedKeys={[selectedKey]}
                        items={menuItems}
                        width={collapsed ? '72px' : '264px'}
                    />
                </div>
                <div style={{ marginTop: 'auto' }}></div>
            </Sider>
        </Space>
    );
};

export default SideMenu;
